import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import styles from './PageContainer.module.scss'

const PageContainer = ({ children, title }) => {
  const school = useSelector(state => state.school)
  return (
    <>
      <Helmet>
        <title>{`${title} · ${school.name}`}</title>
      </Helmet>
      <div className={styles.pageContainer}>{children}</div>
    </>
  )
}

PageContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
}

PageContainer.defaultProps = {
  title: '',
}

export default React.memo(PageContainer)
