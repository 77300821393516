import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { setPusherClient } from 'react-pusher'
import Router from './routes/Router'
import {
  Footer, Header, SideMenu, ToastArea, VersionGuard,
} from './components/widgets'
import withApi from './components/hocs/withApi'
import withAppBootstrap from './components/hocs/withAppBootstrap'
import ScroolToTop from './components/hocs/ScroolToTop'
import { setAccessToken } from './redux/actions/auth'
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'
import './App.scss'
import pusherClient from './modules/Pusher'

setPusherClient(pusherClient)

const HeaderWithRouter = withRouter(Header)
const SideMenuWithRouter = withRouter(SideMenu)

const AppContent = withRouter(
  withApi(
    withAppBootstrap((props) => {
      const { isAuthenticated, location } = props
      return (
        <div className="app">
          {isAuthenticated ? <HeaderWithRouter /> : ''}
          {process.env.NODE_ENV !== 'development' && <VersionGuard />}
          <div className="d-flex flex-row">
            {isAuthenticated ? <SideMenuWithRouter /> : ''}
            <div className="flex-grow-1 mt-3 ml-3 mr-3">
              <div className="main-content-container">
                <Router location={location} />
              </div>
            </div>
          </div>
          <Footer />
          <div id="modal-area" />
          <ToastArea />
        </div>
      )
    }),
  ),
)

const App = props => (
  <BrowserRouter>
    <ScroolToTop>
      <AppContent {...props} />
    </ScroolToTop>
  </BrowserRouter>
)

const mapStateToProps = state => ({
  isAuthenticated: state.accessToken.token !== null,
})

const mapDispatchToProps = dispatch => ({
  _setAccessToken: accessToken => dispatch(setAccessToken(accessToken)),
})

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App)
