import Api from '../../../modules/Api'

import { STORE_TOKEN, STORE_PAYLOAD, CLEAR_TOKEN } from '../../constants/auth.constants'

const storePayload = payload => ({
  type: STORE_PAYLOAD,
  payload,
})

const storeAccessToken = token => ({
  type: STORE_TOKEN,
  token,
})

const clearToken = () => ({
  type: CLEAR_TOKEN,
})


// eslint-disable-next-line max-len
export const refreshAccessToken = (refreshToken, onSuccess = null, onError = null) => dispatch => Api.post('auth/refresh', { refreshToken }).then((response) => {
  if (response.data.success) {
    dispatch(setAccessToken(response.data.data.accessToken, refreshToken))
    if (typeof onSuccess === 'function') onSuccess()
  } else if (typeof onError === 'function') onError()
})

export const setAccessToken = (accessToken, refreshToken = null) => (dispatch) => {
  localStorage.setItem('accessToken', accessToken)
  const split = accessToken.split('.')
  const payload = JSON.parse(atob(split[1]))
  dispatch(storePayload(payload))
  dispatch(storeAccessToken(accessToken))

  if (refreshToken !== null) {
    const now = new Date()
    const timeout = Math.round((payload.exp - now.getTime() / 1000) * 0.85)
    localStorage.setItem('refreshToken', refreshToken)
    // We must schedule the token refresh
    setTimeout(
      (rt) => {
        refreshAccessToken(rt)
      },
      timeout * 1000,
      refreshToken,
    )
  }

  if (!localStorage.getItem('schoolUnitiesId')) {
    localStorage.setItem('schoolUnitiesId', payload.suid)
  }
}

export const clearAccessToken = () => (dispatch) => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  dispatch(clearToken())
}

export default {
  setAccessToken,
  clearAccessToken,
}
