import React from 'react'
import PropTypes from 'prop-types'
import styles from './SectionIntro.module.scss'

const SectionIntro = ({ children }) => (
  <div className={styles.sectionIntro}>
    <div>{children}</div>
    <div className={styles.dottedContainer} />
  </div>
)

SectionIntro.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
}

export default SectionIntro
