import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './TabLayout.module.scss'

const TabLayout = ({ tabs, activeTab, onTabSelected }) => (
  <div className={classnames(styles.mainContainer)}>
    <div className={classnames(styles.tabsContainer, 'd-flex align-items-center')}>
      {tabs.map(tab => (
        <a
          key={tab.name}
          href="#"
          className={classnames({
            [styles.active]: tab.name === activeTab,
          })}
          onClick={(e) => {
            e.preventDefault()
            onTabSelected(tab.name)
          }}
        >
          {tab.title}
        </a>
      ))}
    </div>
    <div className={classnames(styles.body)}>{tabs.find(tab => tab.name === activeTab).body}</div>
  </div>
)

TabLayout.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]).isRequired,
      body: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]).isRequired,
    }),
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabSelected: PropTypes.func.isRequired,
}

export default TabLayout
