import React from 'react'
import PropTypes from 'prop-types'
import PersonType from '../../../types/Person.type'
import Card from '../card'

const PersonalInfoPane = ({ person, className }) => {
  const header = (
    <h4>
      <i className="fas fa-user mr-3 text-secondary" />
      Personal
    </h4>
  )

  return (
    <Card header={header} headerHasBorder={false} className={className}>
      <div className="row mb-2">
        <div className="col-4">
          <b className="text-secondary">First Name</b>
        </div>
        <div className="col-8">
          {person.firstName}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          <b className="text-secondary">Last Name</b>
        </div>
        <div className="col-8">
          {person.lastName}
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <b className="text-secondary">Gender</b>
        </div>
        <div className="col-8">
          {person.gender === 'M' ? 'Male' : 'Female'}
        </div>
      </div>
    </Card>
  )
}

PersonalInfoPane.propTypes = {
  person: PersonType.isRequired,
  className: PropTypes.string,
}

PersonalInfoPane.defaultProps = {
  className: '',
}

export default PersonalInfoPane
