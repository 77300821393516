import ApiOperations from './ApiOperations'

export default instance => ({
  retrieve: (params = {}) => ApiOperations.get(instance, '/librarySettings')({
    pathParams: {},
    params: {
      ...params,
    },
  }),
  update: (data, params = {}) => ApiOperations.patch(instance, '/librarySettings')({
    pathParams: {},
    params: {
      ...params,
    },
  }, data),
})
