import ApiOperations from './ApiOperations'
import CrudOperations from './CrudOperations'

export default instance => ({
  ...CrudOperations(instance, '/parents'),
  // retrieves all students associated to a parent
  listStudents: (parentsId, params = {}) => ApiOperations.get(
    instance,
    '/parents/:id/children',
    ['id'],
  )({
    pathParams: { id: parentsId },
    params,
  }),
  // associates new students to a parent
  associateStudents: (parentsId, kindship, studentsIds) => ApiOperations.post(instance, '/parents/:id/students', ['id'])({
    pathParams: { id: parentsId },
  }, {
    kindship,
    studentsIds: JSON.stringify(studentsIds.map(item => item.id)),
  }),
  uploadPicture: (parentsId, blob) => {
    const data = new FormData()
    data.append('pictureFile', blob, 'pictureFile')
    return ApiOperations.post(instance, '/parents/:id/picture', ['id'])({
      pathParams: { id: parentsId },
      isUpload: true,
    }, data)
  },
})
