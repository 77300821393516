import React, { useState } from 'react'
import PersonTypes from 'src/config/PersonTypes'
import Api from 'Modules/Api'
import PropTypes from 'prop-types'
import PersonType from 'Types/Person.type'
import Autocomplete from '../autocomplete'

const PersonsAutocomplete = ({
  selectedPerson,
  setSelectedPerson,
  allowPersonTypeChange,
  personType,
  setPersonType,
  disableChange,
}) => {
  const [personSearchTerm, setPersonSearchTerm] = useState('')

  const [personAutocompleteState, setPersonAutocompleteState] = useState({
    searching: false,
    showAutocompleteOptions: false,
    items: [],
    typing: false,
  })

  const searchPerson = (term) => {
    let personsApi
    if (personType.value === 'STUDENT') personsApi = Api.students
    else if (personType.value === 'TEACHER') personsApi = Api.teachers
    else if (personType.value === 'PARENT') personsApi = Api.parents
    else personsApi = Api.staff

    personsApi.list({
      records: 5,
      sort: 'firstName,asc',
      fields: ['id', 'firstName', 'lastName', 'email'],
      search: term,
    })
      .then(({ data, meta }) => {
        setPersonAutocompleteState({
          ...personAutocompleteState,
          searching: false,
          showAutocompleteOptions: meta.records > 0,
          items: data,
        })
      })
  }

  const handlePersonTypeChange = (type) => {
    setPersonType(type)
    setPersonSearchTerm('')
    setSelectedPerson(null)
    setPersonAutocompleteState({
      ...personAutocompleteState,
      searching: false,
      showAutocompleteOptions: false,
      typing: false,
      items: [],
    })
  }

  const handleStartedTyping = () => {
    setPersonAutocompleteState({
      ...personAutocompleteState,
      typing: true,
    })
  }

  const handleStoppedTyping = (e) => {
    const term = e.target.value
    setPersonAutocompleteState({
      ...personAutocompleteState,
      typing: false,
      searching: term.length > 2,
      showAutocompleteOptions: term.length > 2,
      items: [],
    })
    if (term.length > 2) {
      searchPerson(term)
    }
  }


  return (
    <>
      {!selectedPerson && allowPersonTypeChange && PersonTypes.map(item => (
        <div key={item.value} className="mb-2 custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id={`autocomplete-person-type-${item.value}`}
            name="personType"
            className="custom-control-input"
            checked={personType === item}
            onChange={() => handlePersonTypeChange(item)}
          />
          <label className="custom-control-label" htmlFor={`autocomplete-person-type-${item.value}`}>{item.label}</label>
        </div>
      ))}
      {selectedPerson
        ? (
          <div>
            {`${personType.label} - `}
            <b>
              {selectedPerson.firstName}
              {' '}
              {selectedPerson.lastName}
            </b>
            {' '}
            {!disableChange && (
              <a href="#" onClick={() => setSelectedPerson(null)} className="ml-2 text-primary">
                <i className="fas fa-times-circle" />
              </a>
            )}
            <br />
            {selectedPerson.email}
          </div>
        )
        : (
          <Autocomplete
            id="personsAutocomplete"
            name="personsAutocomplete"
            onItemSelected={item => setSelectedPerson(item)}
            showListOptions={personAutocompleteState.showAutocompleteOptions}
            onInputChange={e => setPersonSearchTerm(e.target.value)}
            listKey={item => item.id}
            value={personSearchTerm}
            onStartedTyping={handleStartedTyping}
            onStoppedTyping={handleStoppedTyping}
            items={personAutocompleteState.items}
            renderListItem={item => <b>{`${item.firstName} ${item.lastName}`}</b>}
            searching={personAutocompleteState.searching}
            placeholder={`Type the ${personType.label} name or email`}
            className="form-control"
          />
        )}
    </>
  )
}

PersonsAutocomplete.propTypes = {
  selectedPerson: PersonType.isRequired,
  setSelectedPerson: PropTypes.func.isRequired,
  personType: PropTypes.string,
  setPersonType: PropTypes.func,
  allowPersonTypeChange: PropTypes.bool,
  disableChange: PropTypes.bool,
}

PersonsAutocomplete.defaultProps = {
  personType: PersonTypes[0],
  setPersonType: () => {},
  allowPersonTypeChange: true,
  disableChange: false,
}

export default PersonsAutocomplete
