import React from 'react'
import styles from './Footer.module.scss'
import footerLogo from '../../../assets/images/footer-logo.svg'

const Footer = () => (
  <div className={styles.footer}>
    heitus.com &copy; Copyright 2019 Heitus Softwares Co.
    <img src={footerLogo} width={83} height={26} alt="Heitus" />
  </div>
)

export default React.memo(Footer)
