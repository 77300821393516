import ApiOperations from './ApiOperations'
import CrudOperations from './CrudOperations'

export default instance => ({
  ...CrudOperations(instance, '/staff'),
  uploadPicture: (staffId, blob) => {
    const data = new FormData()
    data.append('pictureFile', blob, 'pictureFile')
    return ApiOperations.post(instance, '/staff/:id/picture', ['id'])({
      pathParams: { id: staffId },
      isUpload: true,
    }, data)
  },
})
