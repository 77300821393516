import React from 'react'
import PropTypes from 'prop-types'
import ChunkLoadErrorModal from '../components/widgets/modal/ChunkLoadErrorModal'

class RouterErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: !!error.message.match(/Loading chunk (.*?) failed/) }
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return <ChunkLoadErrorModal />
    }

    return children
  }
}

RouterErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default RouterErrorBoundary
