import React, { Suspense, lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import { PageSuspense } from '../components/widgets'
import PrivRoute from './PrivateRoute'
import * as Routes from './Routes'
import RouterErrorBoundary from './RouterErrorBoundary'

// non-lazy routes
import Signin from '../components/pages/shared/signin'
import NotFound from '../components/pages/staff/not-found'

// lazy routes
const Dashboard = lazy(() => import('../components/pages/staff/dashboard'))
const RecoverPassword = lazy(() => import('../components/pages/shared/recover-password'))
const ResetPassword = lazy(() => import('../components/pages/shared/reset-password'))

const AssessmentsList = lazy(() => import('../components/pages/staff/assessments/list/AssessmentsList'))
const AssessmentsDetails = lazy(() => import('../components/pages/shared/assessments/details/AssessmentsDetails'))
const AssessmentsCreate = lazy(() => import('../components/pages/staff/assessments/create/AssessmentsCreate'))
const AssessmentsUpdate = lazy(() => import('../components/pages/staff/assessments/update/AssessmentsUpdate'))

const Calendar = lazy(() => import('../components/pages/shared/calendar/Calendar'))

const ClassesList = lazy(() => import('../components/pages/staff/classes/list/ClassesList'))
const ClassesDetails = lazy(() => import('../components/pages/staff/classes/details/ClassesDetails'))
const ClassesCreate = lazy(() => import('../components/pages/staff/classes/create/ClassesCreate'))
const ClassesUpdate = lazy(() => import('../components/pages/staff/classes/update/ClassesUpdate'))

const DisciplinesList = lazy(() => import('../components/pages/staff/disciplines/list/DisciplinesList'))
const DisciplinesDetails = lazy(() => import('../components/pages/staff/disciplines/details/DisciplinesDetails'))
const DisciplinesCreate = lazy(() => import('../components/pages/staff/disciplines/create/DisciplinesCreate'))
const DisciplinesUpdate = lazy(() => import('../components/pages/staff/disciplines/update/DisciplinesUpdate'))

const GradesDetails = lazy(() => import('../components/pages/staff/grades/details/GradesDetails'))
const GradesUpdate = lazy(() => import('../components/pages/staff/grades/update/GradesUpdate'))

const LessonsCreate = lazy(() => import('../components/pages/staff/lessons/create/LessonsCreate'))
const LessonsDetails = lazy(() => import('../components/pages/staff/lessons/details/LessonsDetails'))
const LessonsUpdate = lazy(() => import('../components/pages/staff/lessons/update/LessonsUpdate'))

const ModulesList = lazy(() => import('../components/pages/staff/modules/list/ModulesList'))
const ModulesDetails = lazy(() => import('../components/pages/staff/modules/details/ModulesDetails'))
const ModulesCreate = lazy(() => import('../components/pages/staff/modules/create/ModulesCreate'))
const ModulesUpdate = lazy(() => import('../components/pages/staff/modules/update/ModulesUpdate'))
const ModuleClassDetails = lazy(() => import('../components/pages/shared/module-classes/details/ModuleClassDetails'))
const ModuleStudentDetails = lazy(() => import('../components/pages/shared/module-students/details/ModuleStudentDetails'))

const ParentsList = lazy(() => import('../components/pages/staff/parents/list/ParentsList'))
const ParentsDetails = lazy(() => import('../components/pages/staff/parents/details/ParentsDetails'))
const ParentsCreate = lazy(() => import('../components/pages/staff/parents/create/ParentsCreate'))
const ParentsUpdate = lazy(() => import('../components/pages/staff/parents/update/ParentsUpdate'))

const RolesList = lazy(() => import('../components/pages/staff/roles/list/RolesList'))
const RolesDetails = lazy(() => import('../components/pages/staff/roles/details/RolesDetails'))
const RolesCreate = lazy(() => import('../components/pages/staff/roles/create/RolesCreate'))
const RolesUpdate = lazy(() => import('../components/pages/staff/roles/update/RolesUpdate'))

const SchoolDetails = lazy(() => import('../components/pages/staff/school/details/SchoolDetails'))
const SchoolUpdate = lazy(() => import('../components/pages/staff/school/update/SchoolUpdate'))

const SchoolYearsList = lazy(() => import('../components/pages/staff/school-years/list/SchoolYearsList'))
const SchoolYearsDetails = lazy(() => import('../components/pages/staff/school-years/details/SchoolYearsDetails'))
const SchoolYearsCreate = lazy(() => import('../components/pages/staff/school-years/create/SchoolYearsCreate'))

const StagesAdjustment = lazy(() => import('../components/pages/staff/school-years/stages-adjustment/StagesAdjustment'))

const StaffList = lazy(() => import('../components/pages/staff/staff/list/StaffList'))
const StaffDetails = lazy(() => import('../components/pages/staff/staff/details/StaffDetails'))
const StaffCreate = lazy(() => import('../components/pages/staff/staff/create/StaffCreate'))
const StaffUpdate = lazy(() => import('../components/pages/staff/staff/update/StaffUpdate'))

const StudentsList = lazy(() => import('../components/pages/staff/students/list/StudentsList'))
const StudentsDetails = lazy(() => import('../components/pages/staff/students/details/StudentsDetails'))
const StudentsCreate = lazy(() => import('../components/pages/staff/students/create/StudentsCreate'))
const StudentsUpdate = lazy(() => import('../components/pages/staff/students/update/StudentsUpdate'))

const TeachersList = lazy(() => import('../components/pages/staff/teachers/list/TeachersList'))
const TeachersDetails = lazy(() => import('../components/pages/staff/teachers/details/TeachersDetails'))
const TeachersCreate = lazy(() => import('../components/pages/staff/teachers/create/TeachersCreate'))
const TeachersUpdate = lazy(() => import('../components/pages/staff/teachers/update/TeachersUpdate'))

const UnitiesList = lazy(() => import('../components/pages/staff/unities/list/UnitiesList'))
const UnitiesDetails = lazy(() => import('../components/pages/staff/unities/details/UnitiesDetails'))
const UnitiesCreate = lazy(() => import('../components/pages/staff/unities/create/UnitiesCreate'))
const UnitiesUpdate = lazy(() => import('../components/pages/staff/unities/update/UnitiesUpdate'))

const LibraryDashboard = lazy(() => import('../components/pages/staff/library/dashboard/Dashboard'))
const LibrarySettings = lazy(() => import('../components/pages/staff/library/settings/LibrarySettings'))

const AuthorsList = lazy(() => import('../components/pages/staff/library/authors/list/AuthorsList'))
const AuthorsDetails = lazy(() => import('../components/pages/staff/library/authors/details/AuthorsDetails'))
const AuthorsCreate = lazy(() => import('../components/pages/staff/library/authors/create/AuthorsCreate'))
const AuthorsUpdate = lazy(() => import('../components/pages/staff/library/authors/update/AuthorsUpdate'))

const BookCategoriesList = lazy(() => import('../components/pages/staff/library/book-categories/list/BookCategoriesList'))
const BookCategoriesCreate = lazy(() => import('../components/pages/staff/library/book-categories/create/BookCategoriesCreate'))
const BookCategoriesDetails = lazy(() => import('../components/pages/staff/library/book-categories/details/BookCategoriesDetails'))
const BookCategoriesUpdate = lazy(() => import('../components/pages/staff/library/book-categories/update/BookCategoriesUpdate'))

const PublishersList = lazy(() => import('../components/pages/staff/library/publishers/list/PublishersList'))
const PublishersCreate = lazy(() => import('../components/pages/staff/library/publishers/create/PublishersCreate'))
const PublishersDetails = lazy(() => import('../components/pages/staff/library/publishers/details/PublishersDetails'))
const PublishersUpdate = lazy(() => import('../components/pages/staff/library/publishers/update/PublishersUpdate'))

const BooksList = lazy(() => import('../components/pages/staff/library/books/list/BooksList'))
const BooksCreate = lazy(() => import('../components/pages/staff/library/books/create/BooksCreate'))
const BooksDetails = lazy(() => import('../components/pages/staff/library/books/details/BooksDetails'))
const BooksUpdate = lazy(() => import('../components/pages/staff/library/books/update/BooksUpdate'))

const BookCopiesDetails = lazy(() => import('../components/pages/staff/library/book-copies/BookCopiesDetails'))

const ReservationsList = lazy(() => import('../components/pages/staff/library/reservations/list/ReservationsList'))

const LoansList = lazy(() => import('../components/pages/staff/library/loans/list/LoansList'))
const LoansDetails = lazy(() => import('../components/pages/staff/library/loans/details/LoansDetails'))

const TeachersClassesList = lazy(() => import('../components/pages/teachers/classes/list/TeachersClassesList'))

const FinesList = lazy(() => import('../components/pages/staff/fines/list/FinesList'))
const FinesDetails = lazy(() => import('../components/pages/staff/fines/details/FinesDetails'))
const FinesCreate = lazy(() => import('../components/pages/staff/fines/create/FinesCreate'))
const FinesUpdate = lazy(() => import('../components/pages/staff/fines/update/FinesUpdate'))

const LibraryFinesList = lazy(() => import('../components/pages/staff/library/fines/list/FinesList'))
const LibraryFinesDetails = lazy(() => import('../components/pages/staff/library/fines/details/FinesDetails'))

const TuitionsList = lazy(() => import('../components/pages/staff/tuitions/list/TuitionsList'))
const TuitionsDetails = lazy(() => import('Components/pages/staff/tuitions/details/TuitionsDetails'))
const TuitionValuesList = lazy(() => import('Components/pages/staff/tuition-values/list/TuitionValuesList'))
const TuitionValuesCreate = lazy(() => import('Components/pages/staff/tuition-values/create/TuitionValuesCreate'))
const TuitionValuesDetails = lazy(() => import('Components/pages/staff/tuition-values/details/TuitionValuesDetails'))
const TuitionValuesUpdate = lazy(() => import('Components/pages/staff/tuition-values/update/TuitionValuesUpdate'))

const Router = () => (
  <Suspense fallback={<PageSuspense />}>
    <RouterErrorBoundary>
      <Switch>
        <PrivRoute path="/" component={Dashboard} exact />
        <PrivRoute path={Routes.DASHBOARD} component={Dashboard} exact />
        <PrivRoute path={Routes.STAFF_LIST} component={StaffList} exact />
        <PrivRoute path={Routes.STAFF_DETAILS} component={StaffDetails} exact />
        <PrivRoute path={Routes.STAFF_NEW} component={StaffCreate} exact />
        <PrivRoute path={Routes.STAFF_UPDATE} component={StaffUpdate} exact />
        <PrivRoute path={Routes.ROLES_LIST} component={RolesList} exact />
        <PrivRoute path={Routes.ROLES_NEW} component={RolesCreate} exact />
        <PrivRoute path={Routes.ROLES_DETAILS} component={RolesDetails} exact />
        <PrivRoute path={Routes.ROLES_UPDATE} component={RolesUpdate} exact />
        <PrivRoute path={Routes.UNITIES_LIST} component={UnitiesList} exact />
        <PrivRoute path={Routes.UNITIES_NEW} component={UnitiesCreate} exact />
        <PrivRoute path={Routes.UNITIES_UPDATE} component={UnitiesUpdate} exact />
        <PrivRoute path={Routes.UNITIES_DETAILS} component={UnitiesDetails} exact />
        <PrivRoute path={Routes.TEACHERS_LIST} component={TeachersList} exact />
        <PrivRoute path={Routes.TEACHERS_DETAILS} component={TeachersDetails} exact />
        <PrivRoute path={Routes.TEACHERS_NEW} component={TeachersCreate} exact />
        <PrivRoute path={Routes.TEACHERS_UPDATE} component={TeachersUpdate} exact />
        <PrivRoute path={Routes.STUDENTS_LIST} component={StudentsList} exact />
        <PrivRoute path={Routes.STUDENTS_DETAILS} component={StudentsDetails} exact />
        <PrivRoute path={Routes.STUDENTS_NEW} component={StudentsCreate} exact />
        <PrivRoute path={Routes.STUDENTS_UPDATE} component={StudentsUpdate} exact />
        <PrivRoute path={Routes.DISCIPLINES_LIST} component={DisciplinesList} exact />
        <PrivRoute path={Routes.DISCIPLINES_DETAILS} component={DisciplinesDetails} exact />
        <PrivRoute path={Routes.DISCIPLINES_NEW} component={DisciplinesCreate} exact />
        <PrivRoute path={Routes.DISCIPLINES_UPDATE} component={DisciplinesUpdate} exact />
        <PrivRoute path={Routes.SCHOOL_YEARS} component={SchoolYearsList} exact />
        <PrivRoute path={Routes.SCHOOL_YEARS_DETAILS} component={SchoolYearsDetails} exact />
        <PrivRoute path={Routes.SCHOOL_YEARS_NEW} component={SchoolYearsCreate} exact />
        <PrivRoute
          path={Routes.SCHOOL_YEARS_STAGES_ADJUSTMENT}
          component={StagesAdjustment}
          exact
        />
        <PrivRoute path={Routes.MODULES_LIST} component={ModulesList} exact />
        <PrivRoute path={Routes.MODULES_DETAILS} component={ModulesDetails} exact />
        <PrivRoute path={Routes.MODULES_NEW} component={ModulesCreate} exact />
        <PrivRoute path={Routes.MODULES_UPDATE} component={ModulesUpdate} exact />
        <PrivRoute path={Routes.CLASSES_LIST} component={ClassesList} exact />
        <PrivRoute path={Routes.CLASSES_NEW} component={ClassesCreate} exact />
        <PrivRoute path={Routes.CLASSES_DETAILS} component={ClassesDetails} exact />
        <PrivRoute path={Routes.CLASSES_UPDATE} component={ClassesUpdate} exact />
        <PrivRoute path={Routes.MODULE_CLASSES_DETAILS} component={ModuleClassDetails} exact />
        <PrivRoute path={Routes.MODULE_STUDENTS_DETAILS} component={ModuleStudentDetails} exact />
        <PrivRoute path={Routes.ASSESSMENTS_LIST} component={AssessmentsList} exact />
        <PrivRoute path={Routes.ASSESSMENTS_NEW} component={AssessmentsCreate} exact />
        <PrivRoute path={Routes.ASSESSMENTS_DETAILS} component={AssessmentsDetails} exact />
        <PrivRoute path={Routes.ASSESSMENTS_UPDATE} component={AssessmentsUpdate} exact />
        <PrivRoute path={Routes.ASSESSMENTS_GRADES} component={GradesUpdate} exact />
        <PrivRoute path={Routes.GRADES_DETAILS} component={GradesDetails} exact />
        <PrivRoute path={Routes.MODULE_CLASSES_LESSONS_NEW} component={LessonsCreate} exact />
        <PrivRoute path={Routes.MODULE_CLASSES_LESSONS_DETAILS} component={LessonsDetails} exact />
        <PrivRoute path={Routes.MODULE_CLASSES_LESSONS_UPDATE} component={LessonsUpdate} exact />
        <PrivRoute path={Routes.PARENTS_LIST} component={ParentsList} exact />
        <PrivRoute path={Routes.PARENTS_DETAILS} component={ParentsDetails} exact />
        <PrivRoute path={Routes.PARENTS_NEW} component={ParentsCreate} exact />
        <PrivRoute path={Routes.PARENTS_UPDATE} component={ParentsUpdate} exact />
        <PrivRoute path={Routes.CALENDAR} component={Calendar} exact />
        <PrivRoute path={Routes.SCHOOL_DETAILS} component={SchoolDetails} exact />
        <PrivRoute path={Routes.SCHOOL_UPDATE} component={SchoolUpdate} exact />
        <PrivRoute path={Routes.LIBRARY_DASHBOARD} component={LibraryDashboard} exact />
        <PrivRoute path={Routes.LIBRARY_SETTINGS} component={LibrarySettings} exact />
        <PrivRoute path={Routes.LIBRARY_AUTHORS_LIST} component={AuthorsList} exact />
        <PrivRoute path={Routes.LIBRARY_AUTHORS_DETAILS} component={AuthorsDetails} exact />
        <PrivRoute path={Routes.LIBRARY_AUTHORS_NEW} component={AuthorsCreate} exact />
        <PrivRoute path={Routes.LIBRARY_AUTHORS_UPDATE} component={AuthorsUpdate} exact />
        <PrivRoute path={Routes.LIBRARY_CATEGORIES_LIST} component={BookCategoriesList} exact />
        <PrivRoute path={Routes.LIBRARY_CATEGORIES_NEW} component={BookCategoriesCreate} exact />
        <PrivRoute path={Routes.LIBRARY_CATEGORIES_DETAILS} component={BookCategoriesDetails} exact />
        <PrivRoute path={Routes.LIBRARY_CATEGORIES_UPDATE} component={BookCategoriesUpdate} exact />
        <PrivRoute path={Routes.LIBRARY_PUBLISHERS_LIST} component={PublishersList} exact />
        <PrivRoute path={Routes.LIBRARY_PUBLISHERS_NEW} component={PublishersCreate} exact />
        <PrivRoute path={Routes.LIBRARY_PUBLISHERS_DETAILS} component={PublishersDetails} exact />
        <PrivRoute path={Routes.LIBRARY_PUBLISHERS_UPDATE} component={PublishersUpdate} exact />
        <PrivRoute path={Routes.LIBRARY_BOOKS_LIST} component={BooksList} exact />
        <PrivRoute path={Routes.LIBRARY_BOOKS_NEW} component={BooksCreate} exact />
        <PrivRoute path={Routes.LIBRARY_BOOKS_DETAILS} component={BooksDetails} exact />
        <PrivRoute path={Routes.LIBRARY_BOOKS_UPDATE} component={BooksUpdate} exact />
        <PrivRoute path={Routes.LIBRARY_BOOK_COPIES_DETAILS} component={BookCopiesDetails} exact />
        <PrivRoute path={Routes.LIBRARY_LOANS_LIST} component={LoansList} exact />
        <PrivRoute path={Routes.LIBRARY_LOANS_DETAILS} component={LoansDetails} exact />
        <PrivRoute path={Routes.LIBRARY_RESERVATIONS_LIST} component={ReservationsList} exact />
        <PrivRoute path={Routes.TEACHERS_CLASSES} component={TeachersClassesList} exact />
        <PrivRoute path={Routes.FINES_LIST} component={FinesList} exact />
        <PrivRoute path={Routes.FINES_DETAILS} component={FinesDetails} exact />
        <PrivRoute path={Routes.FINES_NEW} component={FinesCreate} exact />
        <PrivRoute path={Routes.FINES_UPDATE} component={FinesUpdate} exact />
        <PrivRoute path={Routes.LIBRARY_FINES_LIST} component={LibraryFinesList} exact />
        <PrivRoute path={Routes.LIBRARY_FINES_DETAILS} component={LibraryFinesDetails} exact />
        <PrivRoute path={Routes.TUITIONS_LIST} component={TuitionsList} exact />
        <PrivRoute path={Routes.TUITIONS_DETAILS} component={TuitionsDetails} exact />
        <PrivRoute path={Routes.TUITION_VALUES_LIST} component={TuitionValuesList} exact />
        <PrivRoute path={Routes.TUITION_VALUES_NEW} component={TuitionValuesCreate} exact />
        <PrivRoute path={Routes.TUITION_VALUES_DETAILS} component={TuitionValuesDetails} exact />
        <PrivRoute path={Routes.TUITION_VALUES_UPDATE} component={TuitionValuesUpdate} exact />
        <Route path={Routes.SIGN_IN} component={Signin} exact />
        <Route path={Routes.RECOVER_PASSWORD} component={RecoverPassword} />
        <Route path={Routes.RESET_PASSWORD} component={ResetPassword} />
        <Route path={Routes.NOT_FOUND} component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </RouterErrorBoundary>
  </Suspense>

)

export default Router
