import PropTypes from 'prop-types'

export default PropTypes.shape({
  id: PropTypes.number,
  schoolsId: PropTypes.number,
  type: PropTypes.string,
  scope: PropTypes.string,
  fullday: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  place: PropTypes.string,
  cancelled: PropTypes.bool,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
})
