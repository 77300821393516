import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Manager, Reference, Popper } from 'react-popper'
import SuspendedCard from '../../suspended-card'
import styles from './ProfileMenu.module.scss'
import CardContent from './card-content'

const ProfileMenu = ({
  profileName, profilePicture, handleLogout,
}) => {
  const [open, setOpen] = useState(false)
  const openMenu = () => setOpen(true)
  const closeMenu = () => setOpen(false)

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <a href="#" ref={ref} onClick={openMenu} className={styles.profileMenuIcon}>
            <i className="fas fa-user" />
          </a>
        )}
      </Reference>
      {open && (
      <Popper
        placement="bottom"
      >
        {({
          ref, arrowProps, style, placement,
        }) => (
          <SuspendedCard ref={ref} style={style} placement={placement} arrowProps={arrowProps} onClickOutside={closeMenu}>
            <CardContent profileName={profileName} profilePicture={profilePicture} onLogout={handleLogout} />
          </SuspendedCard>
        )}
      </Popper>
      )}
    </Manager>
  )
}

ProfileMenu.propTypes = {
  profileName: PropTypes.string.isRequired,
  handleLogout: PropTypes.func.isRequired,
  profilePicture: PropTypes.string,
}

ProfileMenu.defaultProps = {
  profilePicture: null,
}

export default ProfileMenu
