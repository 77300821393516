import React from 'react'
import PropTypes from 'prop-types'
import styles from './GrayCard.module.scss'

const GrayCard = ({ children, className }) => (
  <div className={`${styles.grayCard} ${className}`}>
    {children}
  </div>
)

GrayCard.propTypes = {
  children: PropTypes.oneOf([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
}

GrayCard.defaultProps = {
  children: <></>,
  className: '',
}

export default GrayCard
