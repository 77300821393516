import React from 'react'
import PropTypes from 'prop-types'

const Card = (props) => {
  const {
    header, footer, children, noMargin, className, headerClassName, bodyClassName, footerClassName,
    headerHasBorder,
  } = props
  return (
    <div className={`card ${className} ${noMargin ? '' : 'mb-3'}`}>
      {!header || <div className={`card-header ${headerClassName}${!headerHasBorder && 'no-border'}`}>{header}</div>}
      <div className={`card-body ${bodyClassName}`}>{children}</div>
      {!footer || <div className={`card-footer ${footerClassName}`}>{footer}</div>}
    </div>
  )
}

Card.propTypes = {
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.string]),
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  className: PropTypes.string,
  headerHasBorder: PropTypes.bool,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  noMargin: PropTypes.bool,
}

Card.defaultProps = {
  className: '',
  headerClassName: '',
  headerHasBorder: true,
  bodyClassName: '',
  footerClassName: '',
  header: null,
  footer: null,
  noMargin: false,
  children: '',
}

export default Card
