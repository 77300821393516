import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Manager, Popper, Reference } from 'react-popper'
import List from './List'
import Loading from '../loading'

const propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  showListOptions: PropTypes.bool.isRequired,
  listKey: PropTypes.func.isRequired,
  renderListItem: PropTypes.func.isRequired,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  onStartedTyping: PropTypes.func,
  onStoppedTyping: PropTypes.func,
  searching: PropTypes.bool,
  searchingLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  onClickOutside: PropTypes.func,
  disabled: PropTypes.bool,
}

const defaultProps = {
  inputRef: null,
  onStartedTyping: () => {},
  onStoppedTyping: () => {},
  searching: false,
  searchingLabel: <Loading containerClassName="text-left" message="Searching..." />,
  id: '',
  name: '',
  value: '',
  placeholder: '',
  onFocus: () => {},
  onBlur: () => {},
  onClick: () => {},
  className: 'form-control',
  onClickOutside: () => {},
  disabled: false,
}

let modalArea = null

const Autocomplete = ({
  inputRef,
  onInputChange,
  onItemSelected,
  searching,
  items,
  renderListItem,
  id,
  name,
  value,
  placeholder,
  onFocus,
  onBlur,
  onClick,
  className,
  onStartedTyping,
  onStoppedTyping,
  searchingLabel,
  showListOptions,
  listKey,
  onClickOutside,
  disabled,
}) => {
  const [timeoutId, setTimeoutId] = useState(null)

  modalArea = modalArea || document.getElementById('modal-area')

  const handleInputChange = (e) => {
    onInputChange(e)

    if (timeoutId) clearTimeout(timeoutId)
    else onStartedTyping(e)
    e.persist()

    setTimeoutId(
      setTimeout(() => {
        setTimeoutId(null)
        onStoppedTyping(e)
      }, 300),
    )
  }

  const handleFocus = (e) => {
    onFocus(e)
  }

  const handleBlur = (e) => {
    onBlur(e)
  }

  return (
    <>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} style={{ display: 'block', flexGrow: 1 }}>
              <input
                ref={inputRef}
                id={id}
                name={name}
                value={value}
                placeholder={placeholder}
                type="text"
                className={className}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onClick={onClick}
                disabled={disabled}
              />
            </div>
          )}
        </Reference>
        {showListOptions && (
        <Popper
          placement="bottom"
          modifiers={{
            setPopperWidth: {
              enabled: true,
              order: 849,
              fn: (data) => {
                const { width } = data.offsets.reference
                // eslint-disable-next-line no-param-reassign
                data.styles.width = width
                return data
              },
            },
          }}
        >
          {({
            ref, style,
          }) => ReactDOM.createPortal(
            (
              <div
                ref={ref}
                style={{
                  ...style,
                  zIndex: 999,
                }}
              >
                <List
                  items={items}
                  onItemSelected={onItemSelected}
                  renderListItem={renderListItem}
                  searching={searching}
                  searchingLabel={searchingLabel}
                  listKey={listKey}
                  onClickOutside={onClickOutside}
                />
              </div>
            ), modalArea,
          )}
        </Popper>
        )}
      </Manager>
    </>
  )
}

Autocomplete.propTypes = propTypes
Autocomplete.defaultProps = defaultProps

export default Autocomplete
