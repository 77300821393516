import React from 'react'
import PropTypes from 'prop-types'

const PageFooter = ({ children }) => (<div className="py-3">{children}</div>)

PageFooter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
}

export default React.memo(PageFooter)
