export const actions = {
  TOGGLE_CHECKER: 'TOGGLE_CHECKER',
  SET_NEW_VERSION_AVAILABLE: 'SET_NEW_VERSION_AVAILABLE',
}

const reducer = (state, action) => {
  switch (action) {
    case actions.TOGGLE_CHECKER:
      return {
        ...state,
        checker: !state.checker,
      }
    case actions.SET_NEW_VERSION_AVAILABLE:
      return {
        ...state,
        newVersionAvailable: true,
      }
    default:
      throw new Error()
  }
}

export default reducer
