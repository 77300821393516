import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { parseISO, isToday, getDate } from 'date-fns'

import CalendarEvent from '../CalendarEvent'
import CalendarEventType from '../../../../types/CalendarEvent.type'
import * as DateHelpers from '../../../../helpers/Dates'
import styles from './WeekView.module.scss'

const WeekView = ({
  dateContext, events, onEventUpdate, onEventDelete,
}) => {
  const dayColumns = []
  const startOfWeek = DateHelpers.startOf(dateContext, 'week')
  const endOfWeek = DateHelpers.endOf(dateContext, 'week')

  const eventsByDay = {}
  events.sort((a, b) => {
    if (a.startDate < b.startDate) return -1
    if (a.startDate > b.startDate) return 1
    return 0
  })
  events.forEach((event) => {
    const startDate = parseISO(event.startDate)
    const endDate = parseISO(event.endDate)

    for (let day = startDate.getDate(); day <= Math.min(endDate.getDate(), endOfWeek.getDate()); day += 1) {
      if (!eventsByDay[day]) {
        eventsByDay[day] = []
      }
      eventsByDay[day].push(event)
    }
  })

  for (let i = 0; i < 7; i += 1) {
    const day = DateHelpers.add(startOfWeek, i, 'day')
    const dayOfMonth = getDate(day)

    const header = (
      <div className={styles.dayHeader}>
        <span className={classnames({ [styles.today]: isToday(day) })}>
          {DateHelpers.formatLocalized(day, 'eee d')}
        </span>
      </div>
    )

    const dayEvents = (
      <div className={styles.dayEvents}>
        {eventsByDay[dayOfMonth]
          && eventsByDay[dayOfMonth].map(event => (
            <CalendarEvent
              key={event.id}
              event={event}
              viewMode="WEEK"
              onDelete={onEventDelete}
              onUpdate={onEventUpdate}
            />
          ))}
      </div>
    )

    dayColumns.push(
      <div key={day} className={styles.dayColumn}>
        {header}
        {dayEvents}
      </div>,
    )
  }

  return <div className={styles.weekView}>{dayColumns}</div>
}

WeekView.propTypes = {
  events: PropTypes.arrayOf(CalendarEventType).isRequired,
  dateContext: PropTypes.instanceOf(Date).isRequired,
  onEventUpdate: PropTypes.func.isRequired,
  onEventDelete: PropTypes.func.isRequired,
}

export default WeekView
