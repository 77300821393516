import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { GithubPicker } from 'react-color'
import classnames from 'classnames'
import styles from './ColorPicker.module.scss'

const ColorPicker = ({
  color, onChange, className, pallet,
}) => {
  const [visible, setVisible] = useState(false)
  return (
    <div className={classnames(styles.swatch, className)} onClick={() => setVisible(!visible)}>
      <div className={styles.color} style={{ backgroundColor: color }} />
      {visible && (
        <div className={styles.popover}>
          <div className={styles.cover} onClick={() => setVisible(false)} />
          <GithubPicker colors={pallet} color={color} onChange={c => onChange(c)} />
        </div>
      )}
    </div>
  )
}

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  pallet: PropTypes.arrayOf(PropTypes.string),
}

ColorPicker.defaultProps = {
  className: '',
  pallet: [
    '#0000FF',
    '#00BFFF',
    '#40E0D0',
    '#32CD32',
    '#228B22',
    '#FFFF00',
    '#B8860B',
    '#B22222',
    '#FFA500',
    '#FF0000',
    '#FF69B4',
    '#A020F0',
    '#CD5C5C',
    '#DA70D6',
  ],
}

export default ColorPicker
