import ApiOperations from './ApiOperations'
import CrudOperations from './CrudOperations'

export default instance => ({
  ...CrudOperations(instance, '/schoolYears'),

  // retrieve all stages related to this school year
  listStages: (schoolYearsId, params = {}) => ApiOperations.get(instance, '/schoolYears/:id/stages', ['id'])({
    pathParams: { id: schoolYearsId },
    params,
  }),

  // updates the stages boundaries within a school year
  updateStages: (schoolYearsId, stagesData) => ApiOperations.put(instance, '/schoolYears/:id/stages', ['id'])({
    pathParams: { id: schoolYearsId },
  }, {
    stages: JSON.stringify(stagesData),
  }),
})
