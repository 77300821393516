// eslint-disable-next-line import/prefer-default-export
export const getCroppedImg = (image, crop, fileName) => {
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  let width = crop.width * scaleX
  let height = crop.height * scaleY

  if (width > height && height > 600) {
    width *= 600 / height
    height = 600
  } else if (height > width && width > 600) {
    height *= 600 / width
    width = 600
  }

  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    width,
    height,
  )

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      // eslint-disable-next-line no-param-reassign
      blob.name = fileName
      // eslint-disable-next-line no-param-reassign
      blob.lastModifiedDate = new Date()
      resolve(blob)
    }, 'image/jpeg', 1)
  })
}
