import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './TermItem.module.scss'

const TermItem = ({ label, selected, onClick }) => (
  <a href="#" onClick={(e) => { e.preventDefault(); onClick() }} className={classnames(styles.termItem, { [styles.selected]: selected })}>
    {label}
  </a>
)

TermItem.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default TermItem
