import Alert from 'Components/widgets/alert'
import CustomDatePicker from 'Components/widgets/date-picker'
import { formatISODate } from 'Helpers/Dates'
import { minMaxSchoolYear } from 'Helpers/Functions'
import { useToast } from 'Hooks'
import useCurrentSchoolUnity from 'Hooks/useCurrentSchoolUnity'
import Api from 'Modules/Api'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import SchoolTypes from 'src/config/SchoolTypes'
import { TUITION_VALUES_LIST } from 'Routes'
import ConfirmationModal from '../modal/ConfirmationModal'

const GenerateTuitionsModal = ({
  onGenerated,
  visible,
  onClose,
}) => {
  const { pushMessage, LONG } = useToast()
  const currentUnity = useCurrentSchoolUnity()
  const [state, setState] = useState({
    schoolType: 'FS1',
    schoolYear: 1,
    startDate: null,
    endDate: null,
    processing: false,
  })

  const handleConfirm = () => {
    setState(prev => ({ ...prev, processing: true }))
    const data = {
      schoolType: state.schoolType,
      schoolYear: state.schoolYear,
      startDate: formatISODate(state.startDate),
      endDate: formatISODate(state.endDate),
    }
    Api.unities.generateTuitions(currentUnity.id, data).then(({ meta }) => {
      pushMessage(LONG, `${meta.totalRecords} tuitions ${meta.totalRecords === 1 ? 'was' : 'were'} generated.`)
      setState(prev => ({ ...prev, processing: false }))
      onGenerated()
    })
  }

  const handleCancel = () => {
    onClose()
  }

  const handleChange = (e) => {
    const {
      name,
      value,
    } = e.currentTarget
    setState(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const schoolYearOptions = useMemo(() => {
    const data = []
    const [minSchoolYear, maxSchoolYear] = minMaxSchoolYear(state.schoolType)
    for (let i = minSchoolYear; i <= maxSchoolYear; i += 1) {
      if (i === 1) {
        data.push(<option key={i} value={i}>1st Year</option>)
      } else if (i === 2) {
        data.push(<option key={i} value={i}>2nd Year</option>)
      } else {
        data.push(
          <option key={i} value={i}>
            {i}
            th Year
          </option>,
        )
      }
    }
    return data
  }, [state.schoolType])

  const body = (
    <>
      <div className="row">
        <div className="form-group col-12 col-md-6">
          <label htmlFor="generate-tuition-modal.school-type">School Type:</label>
          <select name="schoolType" id="generate-tuition-modal.school-type" className="form-control" value={state.schoolType} onChange={handleChange}>
            {SchoolTypes.map(item => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-12 col-md-6">
          <label htmlFor="generate-tuition-modal.school-year">School Year:</label>
          <select name="schoolYear" id="generate-tuition-modal.school-year" className="form-control" onChange={handleChange}>
            {schoolYearOptions}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-12 col-md-6">
          <label htmlFor="generate-tuition-modal.start-date">Start Month:</label>
          <CustomDatePicker
            id="generate-tuition-modal.start-date"
            name="startDate"
            showMonthYearPicker
            selected={state.startDate}
            onChange={d => setState(prev => ({
              ...prev,
              startDate: d,
            }))}
            className="form-control"
            dateFormat="MMMM/yyyy"
            maxDate={state.endDate}
            required
          />
        </div>
        <div className="form-group col-12 col-md-6">
          <label htmlFor="generate-tuition-modal.start-date">End Month:</label>
          <CustomDatePicker
            id="generate-tuition-modal.end-date"
            name="endDate"
            showMonthYearPicker
            selected={state.endDate}
            onChange={d => setState(prev => ({
              ...prev,
              endDate: d,
            }))}
            className="form-control"
            dateFormat="MMMM/yyyy"
            minDate={state.startDate}
            required
          />
        </div>
      </div>
      <Alert type="info">
        {'The tuitions will be generated for the selected period based on the '}
        <Link to={TUITION_VALUES_LIST}>Tuition Values configuration</Link>
        {'. If a tuition for the same period already exists for a Student then a new one '}
        <b>will not</b>
        {' be generated.'}
      </Alert>
    </>
  )

  return (
    <ConfirmationModal
      visible={visible}
      onPositive={handleConfirm}
      onNegative={handleCancel}
      header="Generate Tuitions"
      body={body}
      positiveButtonLabel="Generate"
      negativeButtonLabel="Close"
      positiveDisabled={!state.startDate || !state.endDate}
      processing={state.processing}
    />
  )
}

GenerateTuitionsModal.propTypes = {
  visible: PropTypes.bool,
  onGenerated: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

GenerateTuitionsModal.defaultProps = {
  visible: true,
}

export default GenerateTuitionsModal
