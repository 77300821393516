import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Api from '../../../modules/Api'
import * as RouteNames from '../../../routes/Routes'
import { clearAccessToken } from '../../../redux/actions/auth'
import { fetchUnitiesList, defineCurrentUnity } from '../../../redux/actions/unities'
import UnityType from '../../../types/Unity.type'
import SchoolType from '../../../types/School.type'
import UnitySelector from './unity-selector'
import ProfileMenu from './profile-menu'
import './Header.scss'

const Header = ({
  isAuthenticated,
  tokenPayload,
  currentUnity,
  unitiesList,
  _defineCurrentUnity,
  _fetchUnitiesList,
  school,
  history,
  _clearAccessToken,
}) => {
  const handleLogout = () => {
    Api.post('auth/revoke', { refreshToken: localStorage.getItem('refreshToken') })
      .then(() => {
        _clearAccessToken()
        history.push(RouteNames.SIGN_IN)
      })
  }

  useEffect(
    () => {
      if (isAuthenticated && tokenPayload.ctx === 'STAFF') _fetchUnitiesList()
    },
    [isAuthenticated, tokenPayload],
  )

  return (
    <div className="header-container">
      <div>
        <div className="d-flex flex-grow-1 align-items-center ml-3">
          {school.name}
        </div>
        {!isAuthenticated || (
          <>
            {tokenPayload.ctx === 'STAFF' && (
              <>
                <UnitySelector
                  currentUnity={currentUnity}
                  unitiesList={unitiesList}
                  defineCurrentUnity={_defineCurrentUnity}
                />
                <div className="divider" />
              </>
            )}
            <ProfileMenu
              profileName={`${tokenPayload.firstName.split(' ')[0]} ${tokenPayload.lastName.split(' ').pop()}`}
              profilePicture={tokenPayload.pic}
              handleLogout={handleLogout}
            />
          </>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.accessToken.token !== null,
  tokenPayload: state.accessToken.payload,
  currentUnity: state.currentUnity,
  unitiesList: state.unitiesList,
  school: state.school,
})

const mapDispatchToProps = dispatch => ({
  _clearAccessToken: () => dispatch(clearAccessToken()),
  _fetchUnitiesList: () => dispatch(fetchUnitiesList()),
  _defineCurrentUnity: unity => dispatch(defineCurrentUnity(unity)),
})

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  tokenPayload: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    pic: PropTypes.string,
    ctx: PropTypes.string,
  }),
  _clearAccessToken: PropTypes.func.isRequired,
  _fetchUnitiesList: PropTypes.func.isRequired,
  _defineCurrentUnity: PropTypes.func.isRequired,
  currentUnity: UnityType.isRequired,
  unitiesList: PropTypes.arrayOf(UnityType).isRequired,
  school: SchoolType.isRequired,
}

Header.defaultProps = {
  tokenPayload: {},
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(Header))
