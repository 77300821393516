import ApiOperations from 'Modules/api/ApiOperations'
import CrudOperations from './CrudOperations'

export default instance => ({
  ...CrudOperations(instance, '/schoolUnities'),
  generateTuitions: (schoolUnitiesId, data, params = {}) => ApiOperations.post(instance, '/schoolUnities/:id/tuitions', ['id'])({
    pathParams: { id: schoolUnitiesId },
    params,
  }, data),
  listTuitionValues: (schoolUnitiesId, params = {}) => ApiOperations.get(
    instance,
    '/schoolUnities/:id/tuitionValues',
    ['id'],
  )({
    pathParams: { id: schoolUnitiesId },
    params,
  }),
  createTuitionValue: (schoolUnitiesId, data, params = {}) => ApiOperations.post(
    instance,
    '/schoolUnities/:id/tuitionValues',
    ['id'],
  )({
    pathParams: { id: schoolUnitiesId },
    params,
  }, data),
})
