import React from 'react'
import PropTypes from 'prop-types'
import AlertModal from './AlertModal'

const RequestErrorModal = ({ onNeutral }) => (
  <AlertModal
    header={(
      <h5>
        Oh no! There was an error with your request.
      </h5>
      )}
    onNeutral={onNeutral}
    body={(
      <>
        <div className="text-center mb-2">
          <i className="fas fa-exclamation-triangle fa-4x" />
        </div>
        <p>
          We have detected a problem when trying to reach our servers. Since this was
          not expected to happen, we kindly ask you to try again later.
        </p>
      </>
      )}
    visible
    neutralButtonClass="btn-default"
    neutralButtonLabel="close"
  />
)

RequestErrorModal.propTypes = {
  onNeutral: PropTypes.func.isRequired,
}

export default RequestErrorModal
