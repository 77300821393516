import React from 'react'
import PropTypes from 'prop-types'
import PersonType from '../../../types/Person.type'
import Card from '../card'

const AddressInfoPane = ({ person, className }) => {
  const header = (
    <h4>
      <i className="fas fa-map-marker-alt mr-3 text-secondary" />
      Address
    </h4>
  )

  return (
    <Card header={header} headerHasBorder={false} className={className}>
      <div className="row mb-2">
        <div className="col-4 col-md-4">
          <b className="text-secondary">Address</b>
        </div>
        <div className="col">
          {person.addressStreetName}
          {person.addressStreetNumber ? `, ${person.addressStreetNumber}` : ''}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4 col-md-4">
          <b className="text-secondary">Neighborhood</b>
        </div>
        <div className="col">
          {person.addressNeighborhood}
        </div>
      </div>
      <div className="row">
        <div className="col-4 col-md-4">
          <b className="text-secondary">Complement</b>
        </div>
        <div className="col">
          {person.addressComplement}
        </div>
      </div>
      <div className="row">
        <div className="col-4 col-md-4">
          <b className="text-secondary">City</b>
        </div>
        <div className="col">
          {person.addressCity}
          {person.addressState ? ` / ${person.addressState}` : ''}
        </div>
      </div>
    </Card>
  )
}

AddressInfoPane.propTypes = {
  person: PersonType.isRequired,
  className: PropTypes.string,
}

AddressInfoPane.defaultProps = {
  className: '',
}

export default AddressInfoPane
