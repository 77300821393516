import ApiOperations from './ApiOperations'
import CrudOperations from './CrudOperations'

export default instance => ({
  ...CrudOperations(instance, '/authors'),
  listByAuthor: (authorsId, params = {}) => ApiOperations
    .get(instance, '/authors/:id/books', ['id'])({
      pathParams: { id: authorsId },
      params,
    }),
  listByBook: (booksId, params = {}) => ApiOperations
    .get(instance, '/books/:id/authors', ['id'])({
      pathParams: { id: booksId },
      params,
    }),
})
