import React from 'react'
import PropTypes from 'prop-types'
import {
  parseISO, isSameDay, isSameMonth, isSameYear,
} from 'date-fns'
import { formatLocalized } from '../../../../helpers/Dates'
import { capitalizeFirst } from '../../../../helpers/Functions'
import EventType from '../../../../types/CalendarEvent.type'
import styles from './EventDetails.module.scss'

const getTypeLabel = type => capitalizeFirst(type.replace('_', ' '))

const getDates = (event) => {
  const startDate = parseISO(event.startDate)
  const endDate = parseISO(event.endDate)
  if (event.fullday) {
    if (isSameDay(startDate, endDate)) {
      return <>{formatLocalized(startDate, 'PPPP')}</>
    }

    if (isSameMonth(startDate, endDate)) {
      return (
        <>
          {formatLocalized(startDate, 'dd')}
          {' '}
          -
          {formatLocalized(endDate, 'dd MMMM yyyy')}
        </>
      )
    }

    if (isSameYear(startDate, endDate)) {
      return (
        <>
          {formatLocalized(startDate, 'd MMM')}
          {' '}
          -
          {formatLocalized(endDate, 'd MMM yyyy')}
        </>
      )
    }
  }

  if (isSameDay(startDate, endDate)) {
    return (
      <>
        {formatLocalized(startDate, 'PPPP')}
        {' '}
        ⋅
        {formatLocalized(startDate, 'p')}
        {' '}
        to
        {formatLocalized(endDate, 'p')}
      </>
    )
  }

  return (
    <>
      {formatLocalized(startDate, 'PPPp')}
      {' '}
      –
      {formatLocalized(endDate, 'PPPp')}
    </>
  )
}

const EventDetails = ({
  event, onUpdate, onDelete,
}) => (
  <div className={styles.mainContainer}>
    <div className="d-flex align-items-center">
      <h5 className="text-primary mb-0 flex-grow-1">{event.title}</h5>
      {event.personsId !== null && (
        <button type="button" className="btn btn-sm btn-secondary" onClick={() => onUpdate(event)}>
          Update
        </button>
      )}
      <button type="button" className="btn btn-sm btn-danger ml-2" onClick={() => onDelete(event)}>
        <i className="fas fa-trash" />
      </button>
    </div>
    <hr />
    <p>
      <i className="fas fa-clock mr-1" />
      {' '}
      {getDates(event)}
    </p>
    <p>
      <b>Type:</b>
      {' '}
      {getTypeLabel(event.type)}
    </p>
    <p>{event.description}</p>
  </div>
)

EventDetails.propTypes = {
  event: EventType.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default EventDetails
