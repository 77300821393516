import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import QueryString from 'query-string'

const ScrollToTop = ({ children, location: { pathname, search } }) => {
  const { page } = QueryString.parse(search)

  useEffect(
    () => {
      window.scrollTo(0, 0)
    },
    [pathname, page],
  )

  return children
}

ScrollToTop.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
}

export default withRouter(ScrollToTop)
