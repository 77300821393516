import QueryString from 'query-string'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setAccessToken } from '../../redux/actions/auth'
import Api from '../../modules/Api'
import { RequestErrorModal, RequestUnauthorizedModal } from '../widgets'
import * as Routes from '../../routes/Routes'

const transformRequest = (data) => {
  if (data instanceof String) return data
  if (data instanceof FormData) {
    const newData = {}
    data.forEach((value, key) => {
      newData[key] = value
    })
    return QueryString.stringify(newData)
  }
  return QueryString.stringify(data)
}

const withApi = (WrappedComponent) => {
  const Component = (props) => {
    const {
      history,
      _setAccessToken,
    } = props

    const [requestError, setRequestError] = useState(false)
    const [requestUnauthorized, setRequestUnauthorized] = useState(false)

    useEffect(() => {
      // Attaches the authorization header into the request
      Api.interceptors.request.use((config) => {
        Object.assign(config.headers, {
          authorization: localStorage.getItem('accessToken'),
        })

        if (config.isUpload) {
          Object.assign(config, {
            headers: { ...config.headers, 'Content-Type': undefined },
            timeout: 32000,
          })
        } else if (config.method === 'post' || config.method === 'put' || config.method === 'patch') {
          Object.assign(config, {
            headers: {
              ...config.headers,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: transformRequest(config.data),
          })
        }

        const schoolUnitiesId = localStorage.getItem('schoolUnitiesId')
        let params = {}

        // only add the param if not already there
        if (schoolUnitiesId && !config.url.match(/([&?])schoolUnitiesId=/)) {
          params = { schoolUnitiesId }
        }

        return Object.assign(config, { params: { ...config.params, ...params } })
      })

      Api.interceptors.response.use(
        response => response,
        (error) => {
          const originalRequest = error.config

          if (error.response) {
            // Intercepts unauthorized requests
            if (error.response.status === 401) {
              // Don't intercept responses from /auth/refresh Endpoint.(avoids loop)
              // as it returns 401 for invalid refresh tokens
              if (originalRequest.url.match(/\/auth\/refresh/)) {
                return history.push(Routes.SIGN_IN)
              }

              if (!originalRequest.beingRetried) {
                originalRequest.beingRetried = true // Sets that this requests is being retried
                return Api.post('/auth/refresh', { refreshToken: localStorage.getItem('refreshToken') }).then(
                  (response) => {
                    Object.assign(originalRequest.headers, {
                      authorization: response.data.data.accessToken,
                    })
                    // Call Redux to update the token
                    _setAccessToken(response.data.data.accessToken)
                    return Api(originalRequest)
                  },
                )
              }
            }

            if (error.response.status === 403) {
              setRequestUnauthorized(true)
              return Promise.reject(error)
            }

            // Intercepts not found requests
            if (error.response.status === 404) {
              history.push(Routes.NOT_FOUND)
              return Promise.reject(error)
            }
          }

          setRequestError(true)
          return Promise.reject(error)
        },
      )
    }, [])

    return (
      <>
        <WrappedComponent {...props} />
        {requestError && <RequestErrorModal onNeutral={() => setRequestError(false)} />}
        {requestUnauthorized && <RequestUnauthorizedModal onNeutral={() => setRequestUnauthorized(false)} />}
      </>
    )
  }

  Component.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    _setAccessToken: PropTypes.func.isRequired,
  }

  const mapDispatchToProps = dispatch => ({
    _setAccessToken: (aT, rT) => dispatch(setAccessToken(aT, rT)),
  })

  return connect(
    null,
    mapDispatchToProps,
  )(Component)
}

export default withApi
