import React from 'react'
import PropTypes from 'prop-types'
import BasicModal from './BasicModal'
import Loading from '../loading'

const ConfirmationModal = (props) => {
  const {
    visible,
    processing,
    header,
    body,
    onPositive,
    positiveButtonLabel,
    onNegative,
    negativeButtonLabel,
    onClickOutside,
    positiveDisabled,
    negativeDisabled,
  } = props

  const footer = (
    <div>
      {processing ? (
        <Loading message="processing..." />
      ) : (
        <div>
          <button
            type="button"
            className="btn btn-default px-4 mr-1"
            onClick={onNegative}
            disabled={processing || negativeDisabled}
          >
            {negativeButtonLabel}
          </button>
          <button
            type="button"
            className="btn btn-primary px-4 ml-1"
            onClick={onPositive}
            disabled={processing || positiveDisabled}
          >
            {positiveButtonLabel}
          </button>
        </div>
      )}
    </div>
  )

  return (
    <BasicModal
      visible={visible}
      header={header}
      body={body}
      footer={footer}
      onClickOutside={e => (processing ? null : onClickOutside(e))}
    />
  )
}

ConfirmationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  processing: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onClickOutside: PropTypes.func,
  onPositive: PropTypes.func.isRequired,
  positiveButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  positiveDisabled: PropTypes.bool,
  onNegative: PropTypes.func.isRequired,
  negativeButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  negativeDisabled: PropTypes.bool,
}

ConfirmationModal.defaultProps = {
  onClickOutside: () => {},
  positiveButtonLabel: 'Confirm',
  negativeButtonLabel: 'Cancel',
  processing: false,
  positiveDisabled: false,
  negativeDisabled: false,
}

export default ConfirmationModal
