import Pusher from 'pusher-js'

Pusher.logToConsole = false

const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  forceTLS: process.env.REACT_APP_PUSHER_FORCE_TLS,
})

export default pusherClient
