import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactSwitch from 'react-switch/index'

const Switch = ({
  id,
  onChange,
  onClick,
  className,
  checked,
  disabled,
  asFormCheckbox,
  name,
  defaultChecked,
}) => {
  const [stateChecked, setStateChecked] = useState(defaultChecked)

  const handleChange = (c) => {
    setStateChecked(c)
  }

  if (asFormCheckbox) {
    return (
      <span>
        <input
          id={id}
          name={name}
          checked={stateChecked}
          readOnly
          type="checkbox"
          value={1}
          style={{ display: 'none' }}
        />
        <ReactSwitch
          checked={stateChecked}
          onChange={handleChange}
          disabled={disabled}
          onClick={onClick}
          className={className}
          handleDiameter={24}
          height={18}
          width={43}
          boxShadow="0 2px 3px #999"
          onHandleColor="#fcfcfc"
          offHandleColor="#fcfcfc"
        />
      </span>
    )
  }

  return (
    <ReactSwitch
      onChange={onChange}
      onClick={onClick}
      className={className}
      checked={checked}
      disabled={disabled}
      defaultChecked={defaultChecked}
      handleDiameter={22}
      height={18}
      width={42}
      boxShadow="0 2px 3px #999"
      onHandleColor="#fefefe"
      offHandleColor="#fefefe"
    />
  )
}

Switch.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  asFormCheckbox: PropTypes.bool,
  name: PropTypes.string,
}

Switch.defaultProps = {
  onChange: null,
  onClick: null,
  className: '',
  checked: null,
  disabled: false,
  asFormCheckbox: false,
  name: '',
  defaultChecked: false,
  id: '',
}

export default Switch
