import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styles from './Breadcrumb.module.scss'

const Breadcrumb = ({ items }) => (
  <div className={styles.breadcrumbs}>
    {items.map((item, i) => {
      const isLast = i === items.length - 1

      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className={styles.breadcrumbsItem} key={i}>
          {!isLast ? (
            <>
              <Link to={item.route} className={styles.itemLink}>
                {item.content}
              </Link>
              <i className="fas fa-angle-right" />
            </>
          ) : (
            <span className={styles.itemLink}>{item.content}</span>
          )}
        </div>
      )
    })}
  </div>
)

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node.isRequired,
      route: PropTypes.string,
    }),
  ).isRequired,
}

export default Breadcrumb
