import React, { useState } from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'
import { Manager, Reference, Popper } from 'react-popper'
import SuspendedCard from '../suspended-card'
import styles from './CalendarEvent.module.scss'
import CalendarEventType from '../../../types/CalendarEvent.type'
import EventDetails from './event-details/EventDetails'
import * as DateHelpers from '../../../helpers/Dates'

// Calculates if font should be black or white based on its event color.
// Reference: https://codepen.io/cferdinandi/pen/Yomroj
function calcFontColor(hex) {
  const r = parseInt(hex.substr(1, 2), 16)
  const g = parseInt(hex.substr(3, 2), 16)
  const b = parseInt(hex.substr(5, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'black' : 'white'
}

const CalendarEvent = ({
  event, onUpdate, onDelete, viewMode,
}) => {
  const customStyles = {}
  if (event.fullday && event.color) {
    customStyles.backgroundColor = event.color
  }

  const [detailsOpen, setDetailsOpen] = useState(false)

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div
            ref={ref}
            onClick={() => setDetailsOpen(true)}
            className={classnames({
              [styles.eventWeekView]: viewMode === 'WEEK',
              [styles.eventMonthView]: viewMode === 'MONTH',
            })}
          >
            {viewMode === 'WEEK' && (
              <div
                className={classnames(styles.eventInfo, { [styles.eventFullDay]: event.fullday })}
                style={{ backgroundColor: event.color, color: calcFontColor(event.color) }}
              >
                {!event.fullday && (
                  <span className={styles.eventTime}>
                    {DateHelpers.formatLocalized(event.startDate, 'p')}
                    {' - '}
                    {DateHelpers.formatLocalized(event.endDate, 'p')}
                  </span>
                )}
                <span className={styles.eventTitle}>{event.title}</span>
              </div>
            )}

            {viewMode === 'MONTH' && (
              <div
                className={classnames(styles.eventInfo, { [styles.eventFullDay]: event.fullday })}
                style={event.fullday ? { backgroundColor: event.color, color: calcFontColor(event.color) } : {}}
              >
                {!event.fullday && (
                  <>
                    <span
                      className={styles.eventBullet}
                      style={{ backgroundColor: event.color, color: calcFontColor(event.color) }}
                    />
                    <span className={styles.eventTime}>
                      {DateHelpers.formatLocalized(event.startDate, 'p')}
                    </span>
                  </>
                )}
                <span className={styles.eventTitle}>{event.title}</span>
              </div>
            )}
          </div>
        )}
      </Reference>
      {detailsOpen && (
        <Popper placement="auto" modifiers={{ keepTogether: { enabled: true }, flip: { enabled: true } }}>
          {({
            ref, style, placement, arrowProps,
          }) => (
            <SuspendedCard onClickOutside={() => setDetailsOpen(false)} ref={ref} style={style} placement={placement} arrowProps={arrowProps}>
              <EventDetails
                onUpdate={onUpdate}
                onDelete={onDelete}
                event={event}
              />
            </SuspendedCard>
          )}
        </Popper>
      )}
    </Manager>
  )
}

CalendarEvent.propTypes = {
  event: CalendarEventType.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  viewMode: PropTypes.oneOf(['MONTH', 'WEEK']),
}

CalendarEvent.defaultProps = {
  viewMode: 'MONTH',
}

export default CalendarEvent
