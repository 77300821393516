import { compile } from 'path-to-regexp'

export const DASHBOARD = '/dashboard'
export const SIGN_IN = '/sign-in'
export const NOT_FOUND = '/not-found'
export const RECOVER_PASSWORD = '/recover-password'
export const RESET_PASSWORD = '/reset-password/:token'

export const STAFF_LIST = '/staff'
export const STAFF_DETAILS = '/staff/details/:id'
export const STAFF_NEW = '/staff/new'
export const STAFF_UPDATE = '/staff/update/:id'
export const STAFF_ROLES = '/staff/:id/roles'

export const ROLES_LIST = '/roles'
export const ROLES_NEW = '/roles/new'
export const ROLES_DETAILS = '/roles/details/:id'
export const ROLES_UPDATE = '/roles/update/:id'

export const SCHOOL_DETAILS = '/school'
export const SCHOOL_UPDATE = '/school/update'

export const STUDENTS_LIST = '/students'
export const STUDENTS_NEW = '/students/new'
export const STUDENTS_DETAILS = '/students/details/:id'
export const STUDENTS_UPDATE = '/students/update/:id'

export const TEACHERS_LIST = '/teachers'
export const TEACHERS_NEW = '/teachers/new'
export const TEACHERS_DETAILS = '/teachers/details/:id'
export const TEACHERS_UPDATE = '/teachers/update/:id'

export const DISCIPLINES_LIST = '/disciplines'
export const DISCIPLINES_NEW = '/disciplines/new'
export const DISCIPLINES_DETAILS = '/disciplines/details/:id'
export const DISCIPLINES_UPDATE = '/disciplines/update/:id'

export const PARENTS_LIST = '/parents'
export const PARENTS_NEW = '/parents/new'
export const PARENTS_DETAILS = '/parents/details/:id'
export const PARENTS_UPDATE = '/parents/update/:id'

export const UNITIES_LIST = '/unities'
export const UNITIES_NEW = '/unities/new'
export const UNITIES_DETAILS = '/unities/details/:id'
export const UNITIES_UPDATE = '/unities/update/:id'

export const CALENDAR = '/calendar'
export const CLOUD = '/cloud'
export const NOTIFICATIONS = '/notifications'
export const REPORTS = '/reports'

export const SCHOOL_YEARS = '/years'
export const SCHOOL_YEARS_NEW = '/years/new'
export const SCHOOL_YEARS_DETAILS = '/years/details/:id'
export const SCHOOL_YEARS_STAGES_ADJUSTMENT = '/years/:id/stages'

export const MODULES_LIST = '/modules'
export const MODULES_NEW = '/modules/new'
export const MODULES_DETAILS = '/modules/details/:id'
export const MODULES_UPDATE = '/modules/update/:id'

export const CLASSES_LIST = '/classes'
export const CLASSES_NEW = '/classes/new'
export const CLASSES_DETAILS = '/classes/details/:id'
export const CLASSES_UPDATE = '/classes/update/:id'

export const MODULE_CLASSES_DETAILS = '/modules/:modulesId/classes/:classesId'
export const MODULE_STUDENTS_DETAILS = '/modules/:modulesId/students/:studentsId'
export const MODULE_CLASSES_LESSONS_NEW = '/modules/:modulesId/classes/:classesId/lessons'
export const MODULE_CLASSES_LESSONS_DETAILS = '/modules/:modulesId/classes/:classesId/lessons/details/:lessonsId'
export const MODULE_CLASSES_LESSONS_UPDATE = '/modules/:modulesId/classes/:classesId/lessons/update/:lessonsId'

export const ASSESSMENTS_LIST = '/assessments'
export const ASSESSMENTS_NEW = '/assessments/new'
export const ASSESSMENTS_DETAILS = '/assessments/details/:id'
export const ASSESSMENTS_UPDATE = '/assessments/update/:id'
export const ASSESSMENTS_GRADES = '/assessments/:id/grades'

export const GRADES_DETAILS = '/assessments/:assessmentsId/grades/students/:studentsId'

export const PROFILE_UPDATE = '/update-profile'

export const LIBRARY_DASHBOARD = '/library'
export const LIBRARY_SETTINGS = '/library/settings'

export const LIBRARY_AUTHORS_LIST = '/library/authors'
export const LIBRARY_AUTHORS_NEW = '/library/authors/new'
export const LIBRARY_AUTHORS_DETAILS = '/library/authors/details/:id'
export const LIBRARY_AUTHORS_UPDATE = '/library/authors/update/:id'

export const LIBRARY_CATEGORIES_LIST = '/library/categories'
export const LIBRARY_CATEGORIES_NEW = '/library/categories/new'
export const LIBRARY_CATEGORIES_DETAILS = '/library/categories/details/:id'
export const LIBRARY_CATEGORIES_UPDATE = '/library/categories/update/:id'

export const LIBRARY_PUBLISHERS_LIST = '/library/publishers'
export const LIBRARY_PUBLISHERS_NEW = '/library/publishers/new'
export const LIBRARY_PUBLISHERS_DETAILS = '/library/publishers/details/:id'
export const LIBRARY_PUBLISHERS_UPDATE = '/library/publishers/update/:id'

export const LIBRARY_BOOKS_LIST = '/library/publications'
export const LIBRARY_BOOKS_NEW = '/library/publications/new'
export const LIBRARY_BOOKS_DETAILS = '/library/publications/details/:id'
export const LIBRARY_BOOKS_UPDATE = '/library/publications/update/:id'

export const LIBRARY_BOOK_COPIES_DETAILS = '/library/publication/:booksId/copy/:id'

export const LIBRARY_LOANS_LIST = '/library/loans'
export const LIBRARY_LOANS_DETAILS = '/library/loans/details/:id'

export const LIBRARY_RESERVATIONS_LIST = '/library/reservations'

export const LIBRARY_FINES_LIST = '/library/fines'
export const LIBRARY_FINES_DETAILS = '/library/fines/:id'

export const TEACHERS_CLASSES = '/teachers/classes'
export const TEACHERS_CLASSES_DETAILS = '/teachers/classes/:id'

export const FINES_LIST = '/fines'
export const FINES_DETAILS = '/fines/details/:id'
export const FINES_NEW = '/fines/create'
export const FINES_UPDATE = '/fines/update/:id'

export const TUITIONS_LIST = '/tuitions'
export const TUITIONS_DETAILS = '/tuitions/details/:id'
export const TUITION_VALUES_LIST = '/tuitions/values'
export const TUITION_VALUES_DETAILS = '/tuitions/values/details/:id'
export const TUITION_VALUES_NEW = '/tuitions/values/create'
export const TUITION_VALUES_UPDATE = '/tuitions/values/update/:id'

export const buildRoute = (routeName, params = {}) => {
  const toPathRegex = compile(routeName)
  return toPathRegex(params)
}

export const buildPersonDetailsRoute = (type, id) => {
  switch (type) {
    case 'TEACHER':
      return buildRoute(TEACHERS_DETAILS, { id })
    case 'PARENT':
      return buildRoute(PARENTS_DETAILS, { id })
    case 'STUDENT':
      return buildRoute(STUDENTS_DETAILS, { id })
    case 'STAFF':
      return buildRoute(STAFF_DETAILS, { id })
    default:
      throw new Error(`Invalid person type "${type}"`)
  }
}
