import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Loading = (props) => {
  const {
    message, showSpinner, containerClassName, spinnerIconClassName, showMessage,
  } = props
  return (
    <div className={containerClassName || 'text-center'}>
      {showSpinner && <i className={classnames(`fas fa-spin ${spinnerIconClassName}`, { 'mr-2': showMessage })} />}
      {showMessage && <b>{message}</b>}
    </div>
  )
}

Loading.propTypes = {
  message: PropTypes.string,
  showSpinner: PropTypes.bool,
  showMessage: PropTypes.bool,
  containerClassName: PropTypes.string,
  spinnerIconClassName: PropTypes.string,
}

Loading.defaultProps = {
  message: 'Loading...',
  showSpinner: true,
  showMessage: true,
  containerClassName: null,
  spinnerIconClassName: 'fa-spinner',
}

export default React.memo(Loading)
