import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './List.module.scss'

const List = ({
  searching, searchingLabel, renderListItem, items, onItemSelected, listKey, onClickOutside,
}) => {
  const listRef = useRef(null)
  useEffect(() => {
    const handler = (e) => {
      if (!listRef.current.contains(e.target)) {
        onClickOutside()
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  return (
    <div ref={listRef} className={styles.listContainer}>
      {searching && <div className={styles.searchingLabel}>{searchingLabel}</div>}
      {!searching && !items.length && (
        <div>No results found.</div>
      )}
      {items.map(item => (
        <a
          key={listKey(item)}
          href="#"
          onClick={(e) => {
            e.preventDefault()
            onItemSelected(item)
          }}
          className={styles.listItem}
        >
          {renderListItem(item)}
        </a>
      ))}
    </div>
  )
}

List.propTypes = {
  onItemSelected: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  listKey: PropTypes.func.isRequired,
  renderListItem: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
  searchingLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
  onClickOutside: PropTypes.func.isRequired,
}

export default List
