import { CLEAR, PUSH, REMOVE } from '../constants/toast.constants'

export const toastMessages = (state = [], action) => {
  switch (action.type) {
    case PUSH:
      return [
        ...state,
        {
          id: action.id,
          message: action.message,
          timeoutId: action.timeoutId,
        },
      ]
    case REMOVE:
      return state.filter((item) => {
        if (item.id === action.id) {
          clearTimeout(item.timeoutId)
          return false
        }
        return true
      })
    case CLEAR:
      return []
    default:
      return state
  }
}

export default { toastMessages }
