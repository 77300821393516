import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { useSpring, animated, config } from 'react-spring'

let modalAreaElement = null

const BasicModal = ({
  visible, header, body, footer, onClickOutside, width,
}) => {
  const handleBackdropClick = e => (e.target === e.currentTarget ? onClickOutside() : false)
  modalAreaElement = modalAreaElement || document.getElementById('modal-area')

  return ReactDOM.createPortal(
    (
      <animated.div style={useSpring({ opacity: 1, from: { opacity: 0 }, config: config.wobbly })}>
        <div className={`modal ${visible ? 'modal-visible' : ''}`} onClick={handleBackdropClick} role="presentation">
          <div className="modal-dialog" style={width.length ? { maxWidth: width } : {}}>
            <div className="modal-content">
              <div className="modal-header">{typeof header === 'string' ? <h5>{header}</h5> : header}</div>
              <div className="modal-body">
                <div>{body}</div>
              </div>
              {footer !== '' && <div className="modal-footer justify-content-center">{footer}</div>}
            </div>
          </div>
        </div>
      </animated.div>
    ), modalAreaElement,
  )
}

BasicModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClickOutside: PropTypes.func,
  width: PropTypes.string,
}

BasicModal.defaultProps = {
  onClickOutside: () => {},
  footer: '',
  width: '',
}

export default BasicModal
