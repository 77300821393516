import React from 'react'
import PropTypes from 'prop-types'

const RangePanel = ({ intervals }) => (
  <div className="row justify-content-between align-items-center flex-nowrap">
    {intervals.map((item, index) => (
      <>
        <div className="flex-grow-0 d-flex flex-column align-items-center px-3 px-lg-0 px-xl-3">
          <b className="text-secondary">{item.label}</b>
          <i className="fas fa-circle text-secondary my-2" />
          <span>{item.value}</span>
        </div>
        {index < (intervals.length - 1) && (
          <div className="col px-0" style={{ borderBottom: '1px dotted var(--secondary)' }} />
        )}
      </>
    ))}
  </div>
)

RangePanel.propTypes = {
  intervals: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.node,
  })).isRequired,
}

export default RangePanel
