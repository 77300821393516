import React from 'react'

export default [
  {
    id: 0,
    content: <i className="fas fa-home" />,
    route: '/',
  },
  {
    id: 1,
    content: 'Not Found',
  },
]
