import Api from '../../../modules/Api'
import { UPDATE_SCHOOL } from '../../constants/school.constants'

const updateSchool = school => ({
  type: UPDATE_SCHOOL,
  school,
})

export const setSchoolData = schoolData => (dispatch) => {
  dispatch(updateSchool(schoolData))
}

export const fetchSchoolData = () => dispatch => Api.get('/schools/mine').then((response) => {
  const { data } = response.data
  dispatch(updateSchool(data))
  // apply the visual identity colors to the body
  if (data.primaryColor) {
    document.documentElement.style.setProperty('--primary', data.primaryColor)
    document.documentElement.style.setProperty('--primaryHover', data.primaryColorHover)
    document.body.style.setProperty('--primary', data.primaryColor)
    document.body.style.setProperty('--primaryHover', data.primaryColorHover)
  }
  if (data.secondaryColor) {
    document.documentElement.style.setProperty('--secondary', data.secondaryColor)
    document.documentElement.style.setProperty('--secondaryHover', data.secondaryColorHover)
    document.body.style.setProperty('--secondary', data.secondaryColor)
    document.body.style.setProperty('--secondaryHover', data.secondaryColorHover)
  }
  return data
})

export default fetchSchoolData
