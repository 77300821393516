import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import DefaultAvatar from '../../../assets/images/default-avatar.svg'
import styles from './Avatar.module.scss'

const Avatar = ({
  src,
  defaultAvatar,
  showUpdateButton,
  updateButtonLabel,
  showRemoveButton,
  removeButtonLabel,
  width,
  height,
  containerClassName,
  onChangePicture,
  onRemovePicture,
}) => (
  <div className={classnames(styles.userAvatar, 'd-flex flex-column align-items-center', containerClassName)}>
    <div className="image-container">
      {src ? (
        <div className={styles.avatarContainer}>
          <img alt="User avatar" src={src} width={width} height={height} />
        </div>
      ) : (
        <div className={styles.defaultAvatarContainer} style={{ width, height }}>
          <img alt="User avatar" src={defaultAvatar} width="60%" height="60%" />
        </div>
      )}
    </div>
    {!showUpdateButton || (
    <button type="button" className="btn btn-sm btn-primary mt-3" onClick={onChangePicture}>
      {updateButtonLabel}
    </button>
    )}
    {!showRemoveButton || (
      <button type="button" className="btn btn-sm btn-default mt-1" onClick={onRemovePicture}>
        {removeButtonLabel}
      </button>
    )}
  </div>
)

Avatar.propTypes = {
  src: PropTypes.string,
  showUpdateButton: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  containerClassName: PropTypes.string,
  onChangePicture: PropTypes.func,
  onRemovePicture: PropTypes.func,
  defaultAvatar: PropTypes.string,
  updateButtonLabel: PropTypes.string,
  showRemoveButton: PropTypes.bool,
  removeButtonLabel: PropTypes.string,
}

Avatar.defaultProps = {
  src: null,
  showUpdateButton: false,
  width: 100,
  height: 100,
  containerClassName: '',
  onChangePicture: () => {},
  onRemovePicture: () => {},
  defaultAvatar: DefaultAvatar,
  updateButtonLabel: 'Change picture',
  showRemoveButton: false,
  removeButtonLabel: 'Remove picture',
}

export default Avatar
