import PropTypes from 'prop-types'

export default PropTypes.shape({
  id: PropTypes.number,
  schoolsId: PropTypes.number,
  citiesId: PropTypes.number,
  name: PropTypes.string,
  address: PropTypes.string,
  staffCounter: PropTypes.number,
  teachersCounter: PropTypes.number,
  studentsCounter: PropTypes.number,
  parentsCounter: PropTypes.number,
  lastLoginAt: PropTypes.string,
  tuitionDueNextMonth: PropTypes.number,
  tuitionDueDay: PropTypes.number,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
})
