import React from 'react'
import { Breadcrumb, Card, PageContainer } from '../../../widgets'
import BreadcrumbDefinitions from './BreadcrumbDefinitions'

export default () => (
  <PageContainer title="Page Not Found">
    <Breadcrumb items={BreadcrumbDefinitions} />
    <Card
      header={(
        <h5>
          <span className="fa fa-times-circle" />
          {' '}
          Page Not Found
        </h5>
      )}
    >
      The page you are looking for was not found.
    </Card>
  </PageContainer>
)
