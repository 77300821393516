import * as RouteNames from '../routes/Routes'

export default [
  {
    label: 'My Classes',
    route: RouteNames.TEACHERS_CLASSES,
    iconClass: 'fas fa-users',
    requiredContext: ['TEACHER'],
  },
]
