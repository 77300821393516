import ApiOperations from './ApiOperations'
import CrudOperations from './CrudOperations'

export default instance => ({
  ...CrudOperations(instance, '/teachers'),
  listDisciplines: (teachersId, params = {}) => ApiOperations.get(instance, '/teachers/:id/disciplines', ['id'])({
    pathParams: { id: teachersId },
    params,
  }),
  uploadPicture: (teachersId, blob) => {
    const data = new FormData()
    data.append('pictureFile', blob, 'pictureFile')
    return ApiOperations.post(instance, '/teachers/:id/picture', ['id'])({
      pathParams: { id: teachersId },
      isUpload: true,
    }, data)
  },
  listModuleClasses: (teachersId, params = {}) => ApiOperations.get(instance, '/teachers/:id/module-classes', ['id'])({
    pathParams: { id: teachersId },
    params,
  }),
  listAssessments: (teachersId, params = {}) => ApiOperations.get(instance, '/teachers/:id/assessments', ['id'])({
    pathParams: { id: teachersId },
    params,
  }),
})
