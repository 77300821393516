import React from 'react'
import PropTypes from 'prop-types'
import PersonType from '../../../types/Person.type'
import Card from '../card'

const ContactInfoPane = ({ person, className }) => {
  const header = (
    <h4>
      <i className="fas fa-phone-alt mr-3 text-secondary" />
      Contact
    </h4>
  )

  return (
    <Card header={header} headerHasBorder={false} className={className}>
      <div className="row mb-2">
        <div className="col-4">
          <b className="text-secondary">E-mail</b>
        </div>
        <div className="col-8">
          {person.email}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          <b className="text-secondary">Telephone</b>
        </div>
        <div className="col-8">
          {person.telphone}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          <b className="text-secondary">Celphone</b>
        </div>
        <div className="col-8">
          {person.celphone}
        </div>
      </div>
    </Card>
  )
}

ContactInfoPane.propTypes = {
  person: PersonType.isRequired,
  className: PropTypes.string,
}

ContactInfoPane.defaultProps = {
  className: '',
}

export default ContactInfoPane
