import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import BasicModal from './BasicModal'

const AlertModal = ({
  visible, header, body, onNeutral, neutralButtonLabel, neutralButtonClass, onClickOutside,
}) => {
  const footer = (
    <button type="button" className={classnames('btn', neutralButtonClass)} onClick={onNeutral}>
      {neutralButtonLabel}
    </button>
  )

  return (
    <BasicModal
      visible={visible}
      header={header}
      body={body}
      footer={footer}
      onClickOutside={onClickOutside}
    />
  )
}

AlertModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onClickOutside: PropTypes.func,
  onNeutral: PropTypes.func.isRequired,
  neutralButtonLabel: PropTypes.oneOfType([PropTypes.string]),
  neutralButtonClass: PropTypes.string,
}

AlertModal.defaultProps = {
  onClickOutside: () => {},
  neutralButtonLabel: 'Ok',
  neutralButtonClass: 'btn-primary',
}

export default AlertModal
