import PropTypes from 'prop-types'

export const shapeDefinition = {
  id: PropTypes.number,
  schoolsId: PropTypes.number,
  schoolUnitiesId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  birthdate: PropTypes.string,
  gender: PropTypes.oneOf(['M', 'F']),
  telphone: PropTypes.string,
  celphone: PropTypes.string,
  email: PropTypes.string,
  profilePictureName: PropTypes.string,
  addressStreetName: PropTypes.string,
  addressStreetNumber: PropTypes.string,
  addressNeighborhood: PropTypes.string,
  addressComplement: PropTypes.string,
  addressCity: PropTypes.string,
  addressState: PropTypes.string,
  addressZipCode: PropTypes.string,
  lastLoginAt: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
}

export default PropTypes.shape(shapeDefinition)
