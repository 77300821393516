import React from 'react'
import PropTypes from 'prop-types'
import Card from '../card'
import Loading from '../loading/Loading'

const BasicInfoPane = ({
  preHeader, title, primaryInfo, secondaryInfo, keyColumns,
  loading, loadingMessage, noHeaderBorder, className,
}) => {
  if (loading) {
    return (
      <Card
        className="h-100"
      >
        <Loading
          message={loadingMessage}
        />
      </Card>
    )
  }
  const header = []
  if (preHeader) header.push(preHeader)
  if (title) {
    const headerFontSize = title.toString().length <= 10 ? 'text-32 text-lg-46' : 'text-18 text-lg-20'
    header.push((
      <h4 className={`text-primary ${headerFontSize}`}>
        {title}
      </h4>
    ))
  }

  const valueColumns = 12 - keyColumns

  return (
    <Card
      className={`h-100 ${className}`}
      header={header.length && header}
      headerHasBorder={!noHeaderBorder}
    >
      {Object.keys(primaryInfo)
        .map(key => primaryInfo[key] !== undefined && (
          <div key={key} className="row mb-2">
            <div className={`col-${keyColumns} text-secondary`}>
              <b>{key}</b>
            </div>
            <div className={`col-${valueColumns}`}>{primaryInfo[key]}</div>
          </div>
        ))}
      {Object.keys(secondaryInfo)
        .map(key => secondaryInfo[key] !== undefined && (
          <div key={key} className="row mb-2 text-11">
            <div className={`col-${keyColumns}`}>
              <b>{key}</b>
            </div>
            <div className={`col-${valueColumns}`}>{secondaryInfo[key]}</div>
          </div>
        ))}
    </Card>
  )
}

BasicInfoPane.propTypes = {
  preHeader: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]),
  title: PropTypes.string,
  primaryInfo: PropTypes.objectOf(PropTypes.any),
  secondaryInfo: PropTypes.objectOf(PropTypes.any),
  keyColumns: PropTypes.number,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  noHeaderBorder: PropTypes.bool,
  className: PropTypes.string,
}

BasicInfoPane.defaultProps = {
  preHeader: null,
  title: '',
  primaryInfo: {},
  secondaryInfo: {},
  keyColumns: 4,
  loading: false,
  loadingMessage: 'Loading...',
  noHeaderBorder: false,
  className: '',
}

export default BasicInfoPane
