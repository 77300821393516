import React from 'react'
import AlertModal from './AlertModal'

const ChunkLoadErrorModal = () => {
  const handleRefresh = () => {
    window.location.reload(true)
  }

  return (
    <AlertModal
      header={(
        <h5>
          Oh no! There was an error in the app!
        </h5>
      )}
      onNeutral={handleRefresh}
      body={(
        <>
          <div className="text-center mb-2">
            <i className="fas fa-exclamation-triangle fa-4x" />
          </div>
          <p>
            We have detected a problem when trying to load some required pieces of the app.
            Usually this means that a new version of the app is available for use. In that case, refreshing the page may solve the problem.
            This could also be caused by a network failure. If refreshing the page does not solve the issue, please check your internet connection.
          </p>
        </>
      )}
      visible
      neutralButtonClass="btn-default"
      neutralButtonLabel="Refresh page"
    />
  )
}

export default ChunkLoadErrorModal
