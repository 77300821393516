import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Manager, Reference, Popper } from 'react-popper'
import SuspendedCard from '../suspended-card'

const BaseTooltip = ({ message, children, type }) => {
  const [isOpen, setIsOpen] = useState(false)

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <Manager>
      <Reference>
        {({ ref }) => children(ref, open, close, toggle)}
      </Reference>
      {isOpen && (
        <Popper placement="top">
          {({
            ref, arrowProps, style, placement,
          }) => (
            <SuspendedCard
              ref={ref}
              style={style}
              placement={placement}
              arrowProps={{
                ...arrowProps,
              }}
              onClickOutside={() => {}}
              small
              primary={type === 'primary'}
              secondary={type === 'secondary'}
            >
              {message}
            </SuspendedCard>
          )}
        </Popper>
      )}
    </Manager>
  )
}

BaseTooltip.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  children: PropTypes.func.isRequired,
}

BaseTooltip.defaultProps = {
  type: 'primary',
}

export default React.memo(BaseTooltip)
