import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './Pill.module.scss'

const Pill = ({
  text, dismissible, onDismiss, secondary,
}) => {
  const classes = classnames(styles.pillContainer, 'd-inline-flex align-items-center', {
    [styles.dismissible]: dismissible,
    [styles.secondary]: secondary,
  })

  return (
    <div className={classes}>
      <div className="flex-grow-1">{text}</div>
      {dismissible && (
        <span role="button" tabIndex="0" className={styles.dismissBtn} onClick={onDismiss} onKeyPress={onDismiss}>
          <i className="fas fa-times" />
        </span>
      )}
    </div>
  )
}

Pill.propTypes = {
  text: PropTypes.string.isRequired,
  dismissible: PropTypes.bool,
  onDismiss: PropTypes.func,
  secondary: PropTypes.bool,
}

Pill.defaultProps = {
  dismissible: false,
  secondary: false,
  onDismiss: () => {},
}

export default Pill
