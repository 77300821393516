import { useDispatch } from 'react-redux'
import {
  pushMessage, remove, clearAll, duration,
} from '../redux/actions/toast'

export default () => {
  const dispatch = useDispatch()
  return {
    pushMessage: (dur, message) => dispatch(pushMessage(dur, message)),
    removeMessage: id => dispatch(remove(id)),
    clearAll: () => dispatch(clearAll()),
    ...duration,
  }
}
