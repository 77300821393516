import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './ContextMenu.module.scss'

const ContextMenu = ({ options, openOn, width }) => {
  const buttonRef = useRef(null)
  const [opened, setOpened] = useState(false)

  const containerClasses = classnames(styles.menuContainer, styles[openOn], {
    [styles.isVisible]: opened,
  })

  useEffect(() => {
    const handler = (e) => {
      if (!buttonRef.current.contains(e.target) && opened) {
        setOpened(false)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  let k = 0

  return (
    <div
      role="menu"
      tabIndex={0}
      ref={buttonRef}
      className={styles.mainBtn}
      onClick={() => setOpened(!opened)}
      onKeyDown={() => setOpened(!opened)}
    >
      <i className="fas fa-ellipsis-v" />
      <div
        className={containerClasses}
        style={{
          width: `${width}px`,
        }}
      >
        {options.map((option) => {
          k += 1
          return (
            <div key={k} className={styles.contextMenuItem}>
              {option}
            </div>
          )
        })}
      </div>
    </div>
  )
}

ContextMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.node])).isRequired,
  openOn: PropTypes.oneOf(['topLeft', 'topRight', 'bottomLeft', 'bottomRight']),
  width: PropTypes.number,
}

ContextMenu.defaultProps = {
  openOn: 'bottomLeft',
  width: 180,
}

export default ContextMenu
