import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { pushMessage } from '../redux/actions/toast'
import * as RouteNames from './Routes'
import UnityType from '../types/Unity.type'

const PrivateRoute = ({
  component: Component, isAuthenticated, currentUnity, pushToastMessage, ...otherProps
}) => (
  <Route
    {...otherProps}
    render={props => (isAuthenticated ? (
      <Component {...props} pushToastMessage={pushToastMessage} currentUnity={currentUnity} />
    ) : (
      <Redirect to={{ pathname: RouteNames.SIGN_IN }} />
    ))
    }
  />
)

const mapStateToProps = state => ({
  isAuthenticated: state.accessToken.token !== null,
  currentUnity: state.currentUnity,
})

const mapDispatchToProps = dispatch => ({
  pushToastMessage: (_duration, message) => dispatch(pushMessage(_duration, message)),
})

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  currentUnity: UnityType.isRequired,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]).isRequired,
  pushToastMessage: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateRoute)
