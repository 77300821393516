import ApiOperations from './ApiOperations'
import CrudOperations from './CrudOperations'

export default instance => ({
  ...CrudOperations(instance, '/students'),
  listParents: (studentsId, params = {}) => ApiOperations.get(
    instance,
    '/students/:id/parents',
    ['id'],
  )({
    pathParams: { id: studentsId },
    params,
  }),
  listTuitions: (studentsId, params) => ApiOperations.get(
    instance,
    '/students/:id/tuitions',
    ['id'],
  )({
    pathParams: { id: studentsId },
    params,
  }),
  uploadPicture: (studentsId, blob) => {
    const data = new FormData()
    data.append('pictureFile', blob, 'pictureFile')
    return ApiOperations.post(instance, '/students/:id/picture', ['id'])({
      pathParams: { id: studentsId },
      isUpload: true,
    }, data)
  },
})
