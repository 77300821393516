import React from 'react'
import Card from '../card'
import loadingGif from '../../../assets/images/loading/loading-128.gif'
import styles from './PageSuspense.module.scss'

const PageSuspense = () => (
  <Card bodyClassName={styles.container}>
    <img alt="" src={loadingGif} width={128} height={128} />
    <p>Loading page...</p>
  </Card>
)

export default React.memo(PageSuspense)
