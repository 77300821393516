import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import {
  baseStyle, acceptStyle, activeStyle, rejectStyle,
} from './styles'


const DropArea = ({
  acceptedMimes, onSelect, multi, label, disabled,
}) => {
  const handleSelect = (files) => {
    onSelect(files)
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: acceptedMimes.join(', '),
    multiple: multi,
    disabled,
    onDropAccepted: handleSelect,
  })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isDragActive,
    isDragReject,
    isDragAccept,
  ])

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <i className="fas fa-file-upload fa-2x mb-3" />
      {label}
    </div>
  )
}

DropArea.propTypes = {
  acceptedMimes: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  multi: PropTypes.bool,
  disabled: PropTypes.bool,
}

DropArea.defaultProps = {
  acceptedMimes: [],
  label: 'Drop the files in this area or click to select',
  multi: true,
  disabled: false,
}

export default DropArea
