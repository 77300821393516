import React, { useState } from 'react'
import PropTypes from 'prop-types'

import PersonTypes from '../../../config/PersonTypes'
import { formatLocalized } from '../../../helpers/Dates'
import { duration } from '../../../redux/actions/toast'
import Avatar from '../avatar'
import Card from '../card'
import PersonType from '../../../types/Person.type'
import PictureModal from '../picture-modal/PictureModal'
import { useToast } from '../../../hooks'

const AvatarBox = ({
  person, personType, onPictureUpdated, showUpdateButton,
}) => {
  const [updatingPicture, setUpdatingPicture] = useState(false)
  const { pushMessage } = useToast()

  const handleUpdateSuccess = (newPictureUrl) => {
    setUpdatingPicture(false)
    pushMessage(duration.LONG, 'Picture updated successfully!')
    onPictureUpdated(newPictureUrl)
  }

  return (
    <Card>
      {updatingPicture && (
        <PictureModal
          person={person}
          personType={personType}
          onClose={() => setUpdatingPicture(false)}
          onSuccess={handleUpdateSuccess}
        />
      )}
      <Avatar
        src={person.profilePictureUrl}
        height={180}
        width={180}
        showUpdateButton={showUpdateButton}
        onChangePicture={() => setUpdatingPicture(true)}
      />
      <h4 className="text-center my-4">{`${person.firstName} ${person.lastName}`}</h4>
      <div className="row text-11 mb-1">
        <div className="col-5 text-right px-1">
          <b className="text-secondary">Created at:</b>
        </div>
        <div className="col-6 px-1">
          {formatLocalized(person.createdAt, 'PPP')}
        </div>
      </div>
      <div className="row text-11">
        <div className="col-5 px-1 text-right">
          <b className="text-secondary">Last login at:</b>
        </div>
        <div className="col-6 px-1">
          {person.lastLoginAt ? formatLocalized(person.lastLoginAt, 'PPPp') : 'Never'}
        </div>
      </div>
    </Card>
  )
}

AvatarBox.propTypes = {
  person: PersonType.isRequired,
  personType: PropTypes.oneOf(PersonTypes.map(i => i.value)).isRequired,
  onPictureUpdated: PropTypes.func.isRequired,
  showUpdateButton: PropTypes.bool,
}

AvatarBox.defaultProps = {
  showUpdateButton: true,
}

export default AvatarBox
