import PropTypes from 'prop-types'
import React, { useState } from 'react'

import CalendarEventType from '../../../types/CalendarEvent.type'
import Loading from '../loading'
import MonthView from './month-view'
import WeekView from './week-view'
import * as DateHelpers from '../../../helpers/Dates'
import styles from './Calendar.module.scss'

const views = {
  MONTH: 'MONTH',
  WEEK: 'WEEK',
}

const getTitle = (dateContext, viewMode) => {
  if (viewMode === 'MONTH') {
    return DateHelpers.formatLocalized(dateContext, 'MMMM yyyy')
  }

  if (viewMode === 'WEEK') {
    const startOfWeek = DateHelpers.startOf(dateContext, 'week')
    const endOfWeek = DateHelpers.endOf(dateContext, 'week')
    return `${DateHelpers.formatLocalized(startOfWeek, 'PP')} - ${DateHelpers.formatLocalized(endOfWeek, 'PP')}`
  }

  return DateHelpers.formatLocalized(dateContext, 'yyyy')
}

const Calendar = ({
  initialViewMode,
  initialDateContext,
  events,
  onChange,
  fetchingEvents,
  onEventUpdate,
  onEventDelete,
}) => {
  const [dateContext, setDateContext] = useState(new Date(initialDateContext))
  const [viewMode, setViewMode] = useState(initialViewMode)

  const handleViewModeChange = (e) => {
    onChange(new Date(dateContext), e.target.value)
    setViewMode(e.target.value)
  }

  const handleTodayClick = () => {
    onChange(new Date(), viewMode)
    setDateContext(new Date())
  }

  const handlePrevClick = () => {
    const newDateContext = DateHelpers.sub(dateContext, 1, viewMode)
    onChange(new Date(newDateContext), viewMode)
    setDateContext(newDateContext)
  }

  const handleNextClick = () => {
    const newDateContext = DateHelpers.add(dateContext, 1, viewMode)
    onChange(new Date(newDateContext), viewMode)
    setDateContext(newDateContext)
  }

  // Render Content
  return (
    <div className={styles.calendarContainer}>
      <div className={styles.header}>
        <div className={styles.leftContent}>
          <button type="button" className="btn btn-sm btn-secondary" onClick={handleTodayClick}>
            Today
          </button>
          {fetchingEvents && <Loading message="Fetching events..." containerClassName="mb-3 position-absolute right-100" />}
        </div>

        <div className={styles.monthNav}>
          <span className={`btn btn-secondary mx-2 ${styles.arrowContainer}`} onClick={handlePrevClick}>
            <i className="fas fa-chevron-left" />
          </span>
          <div className={`${styles.title} text-primary`}>{getTitle(dateContext, viewMode)}</div>
          <span className={`btn btn-secondary mx-2 ${styles.arrowContainer}`} onClick={handleNextClick}>
            <i className="fas fa-chevron-right" />
          </span>
        </div>

        <div className={styles.viewModeSelector}>
          <div className="btn-group btn-group-toggle ">
            <label className={`btn btn-sm btn-secondary ${viewMode === views.WEEK ? 'active' : ''}`}>
              <input type="radio" name="viewMode" value={views.WEEK} onChange={handleViewModeChange} />
              {' '}
              Week
            </label>
            <label className={`btn btn-sm btn-secondary ${viewMode === views.MONTH ? 'active' : ''}`}>
              <input type="radio" name="viewMode" value={views.MONTH} onChange={handleViewModeChange} />
              {' '}
              Month
            </label>
          </div>
        </div>
      </div>

      {viewMode === views.MONTH && (
        <MonthView
          onEventDelete={onEventDelete}
          onEventUpdate={onEventUpdate}
          dateContext={dateContext}
          events={events}
        />
      )}
      {viewMode === views.WEEK && (
        <WeekView
          onEventDelete={onEventDelete}
          onEventUpdate={onEventUpdate}
          dateContext={dateContext}
          events={events}
        />
      )}
    </div>
  )
}

Calendar.propTypes = {
  events: PropTypes.arrayOf(CalendarEventType).isRequired,
  fetchingEvents: PropTypes.bool.isRequired,
  initialDateContext: PropTypes.instanceOf(Date),
  initialViewMode: PropTypes.oneOf(['MONTH', 'WEEK']),
  onChange: PropTypes.func.isRequired,
  onEventUpdate: PropTypes.func.isRequired,
  onEventDelete: PropTypes.func.isRequired,
}

Calendar.defaultProps = {
  initialDateContext: new Date(),
  initialViewMode: 'MONTH',
}

export default Calendar
