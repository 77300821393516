import PropTypes from 'prop-types'

export default PropTypes.shape({
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['single', 'multi', 'input', 'bool', 'boolean', 'date', 'date-month']),
  key: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  renderInput: PropTypes.func,
})
