import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import styles from './DashboardButton.module.scss'

const DashboardButton = ({
  children, target, onClick, disabled, className, secondary,
}) => {
  const history = useHistory()

  const handleClick = () => {
    if (onClick) onClick()
    if (target) history.push(target)
  }

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={handleClick}
      className={classnames(styles.dashboardButton, className, secondary ? styles.secondary : '')}
    >
      {children}
    </button>
  )
}

DashboardButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  target: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  secondary: PropTypes.bool,
}

DashboardButton.defaultProps = {
  target: null,
  onClick: null,
  disabled: false,
  className: '',
  secondary: false,
}

export default DashboardButton
