export default [
  {
    label: 'Student',
    value: 'STUDENT',
  },
  {
    label: 'Teacher',
    value: 'TEACHER',
  },
  {
    label: 'Parent',
    value: 'PARENT',
  },
  {
    label: 'Staff',
    value: 'STAFF',
  },
]
