import { UPDATE_SCHOOL } from '../constants/school.constants'

export const school = (state = {}, action) => {
  if (action.type === UPDATE_SCHOOL) {
    return { ...action.school }
  }

  return state
}

export default { school }
