import PropTypes from 'prop-types'
import React from 'react'
import BaseTooltip from 'Components/widgets/tooltip/BaseTooltip'

const tooltipElement = (ref, open, close, toggle) => (
  <i
    ref={ref}
    onMouseOver={open}
    onMouseOut={close}
    onFocus={open}
    onBlur={close}
    onClick={toggle}
    className="fas fa-question-circle cursor-pointer"
  />
)

const TooltipQuestion = ({ message, type }) => (
  <BaseTooltip message={message} type={type}>
    {tooltipElement}
  </BaseTooltip>
)

TooltipQuestion.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
}

TooltipQuestion.defaultProps = {
  type: 'primary',
}

export default React.memo(TooltipQuestion)
