import Api from '../../../modules/Api'

import {
  SET_CURRENT_UNITY,
  SET_UNITIES_LIST,
  ADD_UNITY_TO_LIST,
  UPDATE_UNITIES_LIST_ITEM,
  REMOVE_UNITY_FROM_LIST,
} from '../../constants/unities.constants'

const setUnitiesList = unitiesList => ({
  type: SET_UNITIES_LIST,
  unitiesList,
})

const setCurrentUnity = unity => ({
  type: SET_CURRENT_UNITY,
  unity,
})

export const fetchUnitiesList = () => (dispatch) => {
  Api.unities.list({ records: 1000 }).then(({ data }) => {
    dispatch(setUnitiesList(data))
    let schoolUnitiesId = localStorage.getItem('schoolUnitiesId')
    if (schoolUnitiesId) {
      schoolUnitiesId = parseInt(schoolUnitiesId, 10)
      const currentSchoolUnity = data.find(el => el.id === schoolUnitiesId)
      dispatch(setCurrentUnity(currentSchoolUnity))
    }
  })
}

export const addUnityToUnitiesList = unity => (dispatch) => {
  dispatch({
    type: ADD_UNITY_TO_LIST,
    unity,
  })
}

export const updateUnitiesListItem = unity => (dispatch) => {
  dispatch({
    type: UPDATE_UNITIES_LIST_ITEM,
    unity,
  })
}

export const removeUnityFromList = unitiesId => (dispatch) => {
  dispatch({
    type: REMOVE_UNITY_FROM_LIST,
    unitiesId,
  })
  const currentUnitiesId = localStorage.getItem('schoolUnitiesId')
  if (currentUnitiesId && parseInt(currentUnitiesId, 10) === unitiesId) {
    localStorage.setItem('schoolUnitiesId', null)
    dispatch(setCurrentUnity({}))
  }
}

export const defineCurrentUnity = unity => (dispatch) => {
  localStorage.setItem('schoolUnitiesId', unity.id ? unity.id : null)
  dispatch(setCurrentUnity(unity))
}

export default {
  setUnitiesList,
  fetchUnitiesList,
  defineCurrentUnity,
  addUnityToUnitiesList,
  updateUnitiesListItem,
  removeUnityFromList,
}
