import React from 'react'
import PropTypes from 'prop-types'

const SortLabel = (props) => {
  const {
    sortKey, currentSortKey, currentSortType, label, onSortChange, disabled,
  } = props

  let iconClass
  let newSortType
  if (sortKey !== currentSortKey) {
    iconClass = 'fa-sort'
    newSortType = 'asc'
  } else if (currentSortType === 'asc') {
    iconClass = 'fa-sort-up'
    newSortType = 'desc'
  } else {
    iconClass = 'fa-sort-down'
    newSortType = 'asc'
  }

  return (
    <a
      href="#"
      className="sort-label"
      onClick={(event) => {
        event.preventDefault()
        return disabled || onSortChange(sortKey, newSortType)
      }}
    >
      {label}
      {' '}
      <i className={`fas ${iconClass} ml-1`} />
    </a>
  )
}

SortLabel.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  sortKey: PropTypes.string.isRequired,
  currentSortKey: PropTypes.string.isRequired,
  currentSortType: PropTypes.oneOf(['asc', 'desc']).isRequired,
  onSortChange: PropTypes.func.isRequired,
}

SortLabel.defaultProps = {
  disabled: false,
}

export default SortLabel
