import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactCrop from 'react-image-crop'
import PersonTypes from '../../../config/PersonTypes'
import PersonType from '../../../types/Person.type'
import ConfirmationModal from '../modal/ConfirmationModal'
import DropArea from '../drop-area/DropArea'
import 'react-image-crop/lib/ReactCrop.scss'
import { getCroppedImg } from './helpers'
import { getPersonTypeApi } from '../../../helpers/Functions'

const PictureModal = ({
  person, personType, onClose, onSuccess,
}) => {
  const [domImage, setDomImage] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [pictureSrc, setPictureSrc] = useState(null)
  const [crop, setCrop] = useState({
    aspect: 1,
    unit: '%',
    height: 100,
  })

  const handleSelect = (files) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => setPictureSrc(reader.result))
    reader.readAsDataURL(files[0])
  }

  const handleSave = async () => {
    setProcessing(true)
    const blob = await getCroppedImg(domImage, crop, 'pictureFile')
    const personApi = getPersonTypeApi(personType)
    const { data } = await personApi.uploadPicture(person.id, blob)
    onSuccess(data.profilePictureUrl)
  }

  const body = pictureSrc ? (
    <div className="d-flex flex-column align-items-center">
      <div className="mb-2">
        Move and/or resize the selection below to select an area of the picture that you want to
        define as the user&apos;s picture
      </div>
      <ReactCrop
        src={pictureSrc}
        crop={crop}
        onImageLoaded={image => setDomImage(image)}
        onChange={newCrop => setCrop({ ...crop, ...newCrop })}
        keepSelection
        disabled={processing}
      />
    </div>
  ) : (
    <DropArea
      acceptedMimes={['image/jpeg', 'image/png', 'image/gif', 'image/webp']}
      onSelect={handleSelect}
      multi={false}
      label="Drop the picture file in this area or click to select"
    />
  )

  return (
    <ConfirmationModal
      header={`Update ${person.firstName}'s picture`}
      body={body}
      visible
      onNegative={onClose}
      onPositive={handleSave}
      positiveDisabled={!pictureSrc}
      positiveButtonLabel="Save Picture"
      processing={processing}
    />
  )
}

PictureModal.propTypes = {
  person: PersonType.isRequired,
  personType: PropTypes.oneOf(PersonTypes.map(i => i.value)).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default PictureModal
