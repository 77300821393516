import PropTypes from 'prop-types'

export default PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  logoImageName: PropTypes.string,
  blazonImageName: PropTypes.string,
  subdomain: PropTypes.string,
  country: PropTypes.string,
  language: PropTypes.string,
  timezone: PropTypes.string,
  primaryColor: PropTypes.string,
  primaryColorHover: PropTypes.string,
  secondaryColor: PropTypes.string,
  secondaryColorHover: PropTypes.string,
  lessonsDuration: PropTypes.number,
  minimumRequiredPresence: PropTypes.number,
  minimumRequiredGrade: PropTypes.number,
  allowConceptDisciplines: PropTypes.bool,
  minimumRequiredConcept: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
})
