import React from 'react'
import PropTypes from 'prop-types'
import AlertModal from './AlertModal'

const RequestUnauthorizedModal = ({ onNeutral }) => (
  <AlertModal
    header={(
      <h5 className="text-danger">
        <i className="fas fa-times-circle mr-2" />
        Request not authorized!
      </h5>
    )}
    onNeutral={onNeutral}
    body={(
      <>
        <p>
          You don&apos;t have the necessary permissions to complete this request. Please, contact
          your supervisor or the system administrator in order to obtain the required permissions.
        </p>
      </>
    )}
    visible
    neutralButtonClass="btn-default"
    neutralButtonLabel="close"
  />
)

RequestUnauthorizedModal.propTypes = {
  onNeutral: PropTypes.func.isRequired,
}

export default RequestUnauthorizedModal
