import ApiOperations from './ApiOperations'
import CrudOperations from './CrudOperations'

export default instance => ({
  ...CrudOperations(instance, '/bookLoans'),
  registerReturnal: loanId => ApiOperations.post(instance, '/bookLoans/:id/completion', ['id'])({
    pathParams: { id: loanId },
  }),
  renew: (loanId, expectedReturnDate) => ApiOperations.post(instance, '/bookLoans/:id/renewal', ['id'])({
    pathParams: { id: loanId },
  }, {
    expectedReturnDate,
  }),
})
