import axios from 'axios'
import AssessmentsOperations from './api/AssessmentsOperations'
import LibrarySettings from './api/LibrarySettings'
import TuitionsOperations from './api/TuitionsOperations'
import TuitionValuesOperations from './api/TuitionValuesOperations'
import BookAuthorsOperations from './api/BookAuthorsOperations'
import BookCategoriesOperations from './api/BookCategoriesOperations'
import BookCopiesOperations from './api/BookCopiesOperations'
import BookLoansOperations from './api/BookLoansOperations'
import BooksOperations from './api/BooksOperations'
import FinesOperations from './api/FinesOperations'
import LibraryFinesOperations from './api/LibraryFinesOperations'
import PublishersOperations from './api/PublishersOperations'
import StaffOperations from './api/StaffOperations'
import TeachersOperations from './api/TeachersOperations'
import DisciplinesOperations from './api/DisciplinesOperations'
import StudentsOperations from './api/StudentsOperations'
import StatesOperations from './api/StatesOperations'
import CitiesOperations from './api/CitiesOperations'
import ParentsOperations from './api/ParentsOperations'
import UnitiesOperations from './api/UnitiesOperations'
import SchoolYearsOperations from './api/SchoolYearsOperations'
import CalendarEventsOperations from './api/CalendarEventsOperations'
import AuthorsOperations from './api/AuthorsOperations'
import ReservationsOperations from './api/ReservationsOperations'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 8000,
  withCredentials: true,
  headers: {
    Accept: 'text/html, application/json, text/plain, */*',
  },
})

export default {
  assessments: AssessmentsOperations(instance),
  staff: StaffOperations(instance),
  teachers: TeachersOperations(instance),
  disciplines: DisciplinesOperations(instance),
  students: StudentsOperations(instance),
  states: StatesOperations(instance),
  cities: CitiesOperations(instance),
  parents: ParentsOperations(instance),
  unities: UnitiesOperations(instance),
  schoolYears: SchoolYearsOperations(instance),
  calendarEvents: CalendarEventsOperations(instance),
  authors: AuthorsOperations(instance),
  bookAuthors: BookAuthorsOperations(instance),
  bookCategories: BookCategoriesOperations(instance),
  books: BooksOperations(instance),
  publishers: PublishersOperations(instance),
  bookCopies: BookCopiesOperations(instance),
  bookLoans: BookLoansOperations(instance),
  reservations: ReservationsOperations(instance),
  fines: FinesOperations(instance),
  libraryFines: LibraryFinesOperations(instance),
  librarySettings: LibrarySettings(instance),
  tuitions: TuitionsOperations(instance),
  tuitionValues: TuitionValuesOperations(instance),
  ...instance,
}
