import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSpring, animated, config } from 'react-spring'
import styles from './SuspendedCard.module.scss'

const arrowClasses = {
  'top-start': 'arrowDown',
  top: 'arrowDown',
  'top-end': 'arrowDown',
  'left-start': 'arrowRight',
  left: 'arrowRight',
  'left-end': 'arrowRight',
  'bottom-start': 'arrowUp',
  bottom: 'arrowUp',
  'bottom-end': 'arrowUp',
  'right-start': 'arrowLeft',
  right: 'arrowLeft',
  'right-end': 'arrowLeft',
}

const SuspendedCard = React.forwardRef(({
  style, className, placement, arrowProps, children, onClickOutside,
  small, secondary, primary,
}, ref) => {
  const innerRef = useRef(null)

  useEffect(() => {
    const handler = (e) => {
      if (innerRef && !innerRef.current.contains(e.target)) onClickOutside()
    }
    document.addEventListener('click', handler)
    return () => {
      document.removeEventListener('click', handler)
    }
  }, [innerRef])

  return (
    <div
      ref={ref}
      style={style}
      className={classnames(styles.container, className, styles[placement], {
        [styles.secondary]: secondary,
        [styles.primary]: primary,
        [styles.small]: small,
      })}
    >
      <animated.div ref={innerRef} style={useSpring({ opacity: 1, from: { opacity: 0 }, config: config.wobbly })}>
        <div
          ref={arrowProps.ref}
          className={classnames(styles[arrowClasses[placement]], {
            [styles.secondary]: secondary,
            [styles.primary]: primary,
            [styles.small]: small,
          })}
          style={arrowProps.style}
        />
        <div
          className={classnames(styles.card, {
            [styles.secondary]: secondary,
            [styles.primary]: primary,
            [styles.small]: small,
          })}
        >
          {children}
        </div>
      </animated.div>
    </div>
  )
})

SuspendedCard.propTypes = {
  placement: PropTypes.oneOf([
    'top-start',
    'top',
    'top-end',
    'left-start',
    'left',
    'left-end',
    'bottom-start',
    'bottom',
    'bottom-end',
    'right-start',
    'right',
    'right-end',
    '',
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]),
  arrowProps: PropTypes.shape({
    ref: PropTypes.func,
    style: PropTypes.shape({
      top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  onClickOutside: PropTypes.func.isRequired,
  small: PropTypes.bool,
  secondary: PropTypes.bool,
  primary: PropTypes.bool,
}

SuspendedCard.defaultProps = {
  placement: '',
  arrowProps: {
    style: {},
  },
  children: '',
  style: {},
  className: '',
  small: false,
  secondary: false,
  primary: false,
}

export default SuspendedCard
