import { combineReducers } from 'redux'

import { accessToken } from './auth.reducer'

import { currentUnity, unitiesList } from './unities.reducer'

import { school } from './school.reducer'

import { toastMessages } from './toast.reducer'

export default combineReducers({
  accessToken,
  currentUnity,
  unitiesList,
  school,
  toastMessages,
})
