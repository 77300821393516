import ApiOperations from './ApiOperations'
import CrudOperations from './CrudOperations'

export default instance => ({
  ...CrudOperations(instance, '/books'),
  uploadCoverImage: (booksId, blob) => {
    const data = new FormData()
    data.append('imageFile', blob, 'imageFile')
    return ApiOperations.post(instance, '/books/:id/cover', ['id'])({
      pathParams: { id: booksId },
      isUpload: true,
    }, data)
  },
  removeCoverImage: booksId => ApiOperations.delete(instance, '/books/:id/cover', ['id'])({
    pathParams: { id: booksId },
  }),
  stats: () => ApiOperations.get(instance, '/books/stats', [])(),
})
