import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { match } from 'path-to-regexp'
import { Link } from 'react-router-dom'
import './SideMenu.scss'
import sideMenuItems from '../../../config/SideMenuItems'

const checkPermissions = (currentPermissions, requiredPermissions) => {
  let hasPermission = false
  requiredPermissions.some((permission) => {
    if (currentPermissions.indexOf(permission) >= 0) {
      hasPermission = true
      return true
    }

    return false
  })

  return hasPermission
}

export const SideMenuPresentation = ({ location, tokenPayload }) => {
  const finalItems = sideMenuItems.filter((item) => {
    if (!item.requiredContext || !item.requiredContext.length) return true
    if (!item.requiredContext.includes(tokenPayload.ctx)) return false
    if (tokenPayload.ctx === 'STAFF' && item.requiredPermissions) {
      return checkPermissions(tokenPayload.permissions || [], item.requiredPermissions)
    }
    return true
  })


  const items = useMemo(() => finalItems.map(item => (
    <li key={`${item.label}`}>
      <Link to={item.route} className={match(item.route, { decode: decodeURIComponent })(location.pathname) ? 'active' : ''}>
        <div>
          <i className={item.iconClass} />
        </div>
        {item.label}
      </Link>
    </li>
  )), [location.pathname, finalItems])

  return (
    <div className="side-menu-container align-self-start">
      <ul>{items}</ul>
    </div>
  )
}

SideMenuPresentation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  tokenPayload: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    ctx: PropTypes.string,
  }).isRequired,
}

const mapStateToProps = state => ({
  tokenPayload: state.accessToken.payload,
})

export default connect(
  mapStateToProps,
  null,
)(React.memo(SideMenuPresentation))
