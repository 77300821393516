import PropTypes from 'prop-types'
import React from 'react'
import {
  getDaysInMonth, parseISO, isToday, differenceInDays,
} from 'date-fns'
import CalendarEvent from '../CalendarEvent'
import styles from './MonthView.module.scss'
import CalendarEventType from '../../../../types/CalendarEvent.type'
import * as DateHelpers from '../../../../helpers/Dates'

const MonthView = ({
  dateContext, events, onEventUpdate, onEventDelete,
}) => {
  const today = new Date()
  const monthCells = []
  const startOfMonth = DateHelpers.startOf(dateContext, 'month')
  const endOfMonth = DateHelpers.endOf(dateContext, 'month')
  const daysInMonth = getDaysInMonth(dateContext)

  const eventsByDay = {}
  events.sort((a, b) => {
    if (a.startDate < b.startDate) return -1
    if (a.startDate > b.startDate) return 1
    return 0
  })
  events.forEach((event) => {
    const startDate = parseISO(event.startDate)
    const endDate = parseISO(event.endDate)

    for (let day = startDate.getDate(); day <= Math.min(endDate.getDate(), endOfMonth.getDate()); day += 1) {
      if (!eventsByDay[day]) {
        eventsByDay[day] = []
      }
      eventsByDay[day].push(event)
    }
  })

  // Build month cells
  const diff = differenceInDays(startOfMonth, DateHelpers.startOf(startOfMonth, 'week'))
  for (let i = 0; i < diff; i += 1) {
    monthCells.push(<div key={`initial-empty-cell-${i}`} className={styles.emptyCell} />)
  }

  for (let i = 1; i <= daysInMonth; i += 1) {
    const isCurrentDay = isToday(DateHelpers.add(startOfMonth, i - 1, 'day'))
    monthCells.push(
      <div key={`cell-${i}`} className={styles.cell}>
        {isCurrentDay ? <span className={`${styles.dayNumber} ${styles.active}`}>{i}</span> : <span className={styles.dayNumber}>{i}</span>}
        <div className={styles.events}>
          {eventsByDay[i]
            && eventsByDay[i].map(event => (
              <CalendarEvent
                onUpdate={onEventUpdate}
                onDelete={onEventDelete}
                key={event.id}
                event={event}
                viewMode="MONTH"
              />
            ))}
        </div>
      </div>,
    )
  }

  for (let i = 0; i < monthCells.length % 7; i += 1) {
    monthCells.push(<div key={`final-empty-cells-${i}`} className={styles.emptyCell} />)
  }

  let dayOfWeek = DateHelpers.startOf(today, 'week')
  const weekDays = []
  for (let i = 0; i < 7; i += 1) {
    weekDays.push(
      <div key={i} className={styles.weekday}>
        {DateHelpers.formatLocalized(dayOfWeek, 'eeee')}
      </div>,
    )
    dayOfWeek = DateHelpers.add(dayOfWeek, 1, 'day')
  }

  return (
    <div className={styles.monthView}>
      <div className={styles.weekdaysContainer}>{weekDays}</div>
      <div className={styles.cellsContainer}>{monthCells}</div>
    </div>
  )
}

MonthView.propTypes = {
  events: PropTypes.arrayOf(CalendarEventType).isRequired,
  dateContext: PropTypes.instanceOf(Date).isRequired,
  onEventUpdate: PropTypes.func.isRequired,
  onEventDelete: PropTypes.func.isRequired,
}

export default MonthView
