import React from 'react'
import PropTypes from 'prop-types'
import styles from './PageHeader.module.scss'

const PageHeader = ({ children }) => (<div className={styles.pageHeader}>{children}</div>)

PageHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
}

export default React.memo(PageHeader)
