import React from 'react'
import './LoadingScreen.scss'
import loading from '../../../assets/images/loading/loading-256.gif'

export default React.memo(() => (
  <div className="text-center py-5 loading-screen-container">
    <div>
      <img alt="" src={loading} width={256} height={256} />
    </div>
  </div>
))
