import React, { useState } from 'react'
import PropTypes from 'prop-types'

const SigninForm = ({ onSubmit, processing, fail }) => {
  const [state, setState] = useState({
    email: '',
    password: '',
    context: 'STUDENT',
  })

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit({ ...state })
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          name="email"
          type="text"
          placeholder="E-mail"
          className="form-control"
          required
          value={state.email}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <input
          name="password"
          type="password"
          placeholder="Password"
          className="form-control"
          value={state.password}
          required
          minLength={6}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <select
          name="context"
          className="form-control custom-form"
          value={state.context}
          onChange={handleChange}
        >
          <option value="STUDENT">Student</option>
          <option value="TEACHER">Teacher</option>
          <option value="PARENT">Parent</option>
          <option value="STAFF">Staff</option>
        </select>
      </div>
      <div className="form-group text-center">
        <button type="submit" disabled={processing} className="btn btn-primary btn-block">
          {processing ? (
            <span>
              <i className="fa fa-spin fa-spinner" />
              {' '}
              Processing...
            </span>
          ) : (
            'Sign in'
          )}
        </button>
      </div>
      {!fail || <div className="alert alert-warning mt-2 text-center">Invalid credentials!</div>}
    </form>
  )
}
SigninForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  fail: PropTypes.bool.isRequired,
}

export default SigninForm
