import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import QueryString from 'query-string'

const Paginator = (props) => {
  const {
    className,
    currentPage,
    totalPages,
    maxPagesToDisplay,
    queryStringParam,
    queryStringAppend,
    route,
    enableControls,
  } = props

  const entries = []
  let k = 0

  const buildQueryString = n => QueryString.stringify({ ...queryStringAppend, [queryStringParam]: n })

  const buildNumericCell = (pageNumber, cellContent, key) => (
    <li key={key} className={`page-item k${key} ${pageNumber === currentPage ? 'active' : ''}`}>
      <Link className="page-link" to={`${route}?${buildQueryString(pageNumber)}`}>
        {cellContent}
      </Link>
    </li>
  )

  const buildArrowsCell = (pageNumber, cellContent, disabled, key) => (
    <li key={key} className={`page-item control ${disabled ? 'disabled' : ''}`}>
      <Link className="page-link" to={`${route}?${buildQueryString(pageNumber)}`}>
        {cellContent}
      </Link>
    </li>
  )

  // Paginator number cells logic

  if (totalPages <= maxPagesToDisplay) {
    for (let i = 0; i < totalPages; i += 1) {
      entries.push(buildNumericCell(i, i + 1, k))
      k += 1
    }
  } else {
    const half = Math.floor(maxPagesToDisplay / 2)
    if (currentPage + half + 1 >= totalPages) {
      for (let i = totalPages - 1; i >= totalPages - maxPagesToDisplay; i -= 1) {
        entries.unshift(buildNumericCell(i, i + 1, k))
        k += 1
      }
    } else if (currentPage <= half) {
      for (let i = 0; i < Math.min(maxPagesToDisplay, totalPages); i += 1) {
        entries.push(buildNumericCell(i, i + 1, k))
        k += 1
      }
    } else {
      let left = currentPage - half
      const right = currentPage + half
      if (maxPagesToDisplay % 2 === 0) {
        left += 1
      }
      for (let i = left; i <= right; i += 1) {
        entries.push(buildNumericCell(i, i + 1, k))
        k += 1
      }
    }
  }

  // Paginator arrow cells logic

  if (enableControls) {
    let cellContent
    let disabled

    // Left arrows

    disabled = currentPage === 0 || totalPages === 0

    cellContent = <i className="fas fa-angle-left" />
    entries.unshift(buildArrowsCell(currentPage - 1, cellContent, disabled, k))
    k += 1

    cellContent = <i className="fas fa-angle-double-left" />
    entries.unshift(buildArrowsCell(0, cellContent, disabled, k))
    k += 1

    // Right Arrows

    disabled = totalPages === 0 || currentPage === totalPages - 1

    cellContent = <i className="fas fa-angle-right" />
    entries.push(buildArrowsCell(currentPage + 1, cellContent, disabled, k))
    k += 1

    cellContent = <i className="fas fa-angle-double-right" />
    entries.push(buildArrowsCell(totalPages - 1, cellContent, disabled, k))
    k += 1
  }

  return <ul className={`pagination mb-0 ${className}`}>{entries}</ul>
}

Paginator.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  maxPagesToDisplay: PropTypes.number,
  queryStringParam: PropTypes.string,
  queryStringAppend: PropTypes.shape({}),
  route: PropTypes.string.isRequired,
  enableControls: PropTypes.bool,
}

Paginator.defaultProps = {
  className: '',
  maxPagesToDisplay: 10,
  queryStringParam: 'page',
  queryStringAppend: {},
  enableControls: true,
}

export default React.memo(Paginator)
