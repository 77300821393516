import {
  ADD_UNITY_TO_LIST,
  SET_CURRENT_UNITY,
  SET_UNITIES_LIST,
  UPDATE_UNITIES_LIST_ITEM,
  REMOVE_UNITY_FROM_LIST,
} from '../constants/unities.constants'

export const currentUnity = (state = {}, action) => {
  switch (action.type) {
    case SET_CURRENT_UNITY:
      return { ...action.unity }
    case UPDATE_UNITIES_LIST_ITEM:
      if (state.id === action.unity.id) {
        return { ...action.unity }
      }
      return state

    default:
      return state
  }
}

export const unitiesList = (state = [], action) => {
  switch (action.type) {
    case SET_UNITIES_LIST:
      return [...action.unitiesList]
    case ADD_UNITY_TO_LIST:
      return [...state, action.unity]
    case UPDATE_UNITIES_LIST_ITEM:
      return [...state.filter(e => e.id !== action.unity.id), action.unity]
    case REMOVE_UNITY_FROM_LIST:
      return [...state.filter(e => e.id !== action.unitiesId)]
    default:
      return state
  }
}

export default {
  currentUnity,
  unitiesList,
}
