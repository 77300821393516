import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import * as RouteNames from '../../../../routes/Routes'
import UnityType from '../../../../types/Unity.type'
import styles from './UnitySelector.module.scss'

const UnitySelector = ({ currentUnity, unitiesList, defineCurrentUnity }) => {
  const dropdownRef = useRef(null)
  const [expanded, setExpanded] = useState(false)
  const dropdownClasses = classnames(`${styles.list}`, {
    [styles.expanded]: expanded,
  })

  useEffect(() => {
    const handler = (e) => {
      if (!dropdownRef.current.contains(e.target) && expanded) {
        setExpanded(false)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  const handleUnitySelected = (e, unity) => {
    e.preventDefault()
    setExpanded(false)
    defineCurrentUnity(unity)
  }

  const handleAllClick = (e) => {
    e.preventDefault()
    setExpanded(false)
    defineCurrentUnity({})
  }

  const unitiesEntries = unitiesList
    .filter(unity => unity.id !== currentUnity.id)
    .map(unity => (
      <a key={unity.id} href="#" onClick={e => handleUnitySelected(e, unity)} className={styles.unityEntry}>
        {unity.name}
      </a>
    ))

  return (
    <div className={styles.selectorContainer}>
      <span className={styles.unityLabel}>UNITY</span>
      <div className={styles.dropdownContainer}>
        <div className={styles.iconContainer}>
          <i className="fas fa-university" />
        </div>
        <button
          type="button"
          onClick={() => setExpanded(!expanded)}
        >
          <span>{currentUnity.id ? currentUnity.name : 'All / No filter'}</span>
          <i className="fas fa-angle-down ml-2 fa-lg" />
        </button>
        <div ref={dropdownRef} className={dropdownClasses}>
          Select unity:
          <div>
            {unitiesEntries}
            <a href="#" onClick={handleAllClick} className={classnames(styles.unityEntry, 'd-flex align-items-center')}>
              <i className="fas fa-university pr-2" />
              All / No filter
            </a>
            <Link
              to={RouteNames.UNITIES_NEW}
              onClick={() => setExpanded(false)}
              className={classnames(styles.unityEntry, 'd-flex align-items-center')}
            >
              <i className="fas fa-plus-circle pr-2" />
              Create a new
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

UnitySelector.propTypes = {
  currentUnity: UnityType.isRequired,
  unitiesList: PropTypes.arrayOf(UnityType).isRequired,
  defineCurrentUnity: PropTypes.func.isRequired,
}

export default UnitySelector
