import React from 'react'
import PropTypes from 'prop-types'
import * as classnames from 'classnames'

const Alert = (props) => {
  const {
    type, children, visible, dismissible, onDismiss, className,
  } = props

  const alertClasses = classnames(
    'alert',
    `alert-${type === 'error' ? 'danger' : type}`,
    { 'alert-dismissible': dismissible },
    'my-1',
    className,
  )

  return visible ? (
    <div className={alertClasses}>
      <div>{children}</div>
      {!dismissible || (
        <button type="button" className="close" onClick={onDismiss}>
          <span aria-hidden="true">&times;</span>
        </button>
      )}
    </div>
  ) : (
    ''
  )
}

Alert.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]).isRequired,
  visible: PropTypes.bool,
  dismissible: PropTypes.bool,
  onDismiss: PropTypes.func,
  type: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
  className: PropTypes.string,
}

Alert.defaultProps = {
  type: 'warning',
  dismissible: false,
  visible: true,
  onDismiss: () => {},
  className: '',
}

export default Alert
