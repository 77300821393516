import ApiOperations from './ApiOperations'
import CrudOperations from './CrudOperations'

export default instance => ({
  ...CrudOperations(instance, '/states'),
  listCities: (stateId, params = {}) => ApiOperations.get(instance, '/states/:id/cities')({
    pathParams: { id: stateId },
    params,
  }),
})
