import { compile } from 'path-to-regexp'

const buildEndpoint = (endpoint, params = {}) => {
  const toPathRegex = compile(endpoint)
  return toPathRegex(params)
}

const validatePathParams = (requiredParams, providedParams) => {
  requiredParams.forEach((param) => {
    if (providedParams[param] === undefined) throw new Error(`Missing required parameter: {${param}}`)
  })
}

const parseConfigAndValidateParams = (requiredPathParams, config) => {
  const baseConfig = {
    ...defaultConfig,
    ...config,
  }
  const finalConfig = {
    ...baseConfig,
    params: {
      ...baseConfig.params,
      fields: Array.isArray(baseConfig.params.fields) ? baseConfig.params.fields.join(',') : baseConfig.params.fields,
      include: Array.isArray(baseConfig.params.include) ? baseConfig.params.include.join(',') : baseConfig.params.include,
    },
  }

  validatePathParams(requiredPathParams, finalConfig.pathParams)
  return finalConfig
}

const processApiResponse = (response) => {
  const {
    success, data, meta, includes, errors,
  } = response.data

  return {
    success, data, meta, includes, errors,
  }
}

const defaultConfig = {
  pathParams: {},
  params: {
    page: null,
    records: null,
    sort: null,
    fields: null,
    include: null,
  },
}

export default {
  get: (api, endpoint, requiredPathParams = []) => (config) => {
    const requestConfig = parseConfigAndValidateParams(requiredPathParams, config)
    return api.get(buildEndpoint(endpoint, requestConfig.pathParams), requestConfig)
      .then(processApiResponse)
  },

  // creates a new resource
  post: (api, endpoint, requiredPathParams = []) => (config, data) => {
    const requestConfig = parseConfigAndValidateParams(requiredPathParams, config)
    return api.post(buildEndpoint(endpoint, requestConfig.pathParams), data, requestConfig)
      .then(processApiResponse)
  },

  // updates all fields of a resource
  put: (api, endpoint, requiredPathParams = []) => (config, data) => {
    const requestConfig = parseConfigAndValidateParams(requiredPathParams, config)
    return api.put(buildEndpoint(endpoint, requestConfig.pathParams), data, requestConfig)
      .then(processApiResponse)
  },

  // updates the given fields of a resource
  patch: (api, endpoint, requiredPathParams = []) => (config, data) => {
    const requestConfig = parseConfigAndValidateParams(requiredPathParams, config)
    return api.patch(buildEndpoint(endpoint, requestConfig.pathParams), data, requestConfig)
      .then(processApiResponse)
  },

  // deletes a resource
  delete: (api, endpoint, requiredPathParams = []) => (config, data) => {
    const requestConfig = parseConfigAndValidateParams(requiredPathParams, config)
    return api.delete(buildEndpoint(endpoint, requestConfig.pathParams), data, requestConfig)
      .then(processApiResponse)
  },
}
