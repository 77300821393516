import PropTypes from 'prop-types'
import React from 'react'
import BaseTooltip from 'Components/widgets/tooltip/BaseTooltip'

const TooltipInfo = ({
  message,
  type,
  children,
}) => (
  <BaseTooltip message={message} type={type}>
    {(ref, open, close, toggle) => (
      <span
        ref={ref}
        onMouseOver={open}
        onMouseOut={close}
        onFocus={open}
        onBlur={close}
        onClick={toggle}
      >
        {children}
      </span>
    )}
  </BaseTooltip>
)

TooltipInfo.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  children: PropTypes.node.isRequired,
}

TooltipInfo.defaultProps = {
  type: 'primary',
}

export default React.memo(TooltipInfo)
