import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Pusher from 'react-pusher'
import { isAfter } from 'date-fns'
import { refreshAccessToken, setAccessToken } from '../../redux/actions/auth'
import { fetchSchoolData } from '../../redux/actions/school'
import { LoadingScreen } from '../widgets'

const withAppBootstrap = (WrappedComponent) => {
  const Component = (props) => {
    const {
      _refreshAccessToken,
      _setAccessToken,
      _fetchSchoolData,
      isAuthenticated,
      tokenPayload,
    } = props

    const [processingToken, setProcessingToken] = useState(true)
    const [fetchingSchool, setFetchingSchool] = useState(true)

    const endProcessing = () => setProcessingToken(false)

    useEffect(() => {
      // fetches the basic info for the current school
      _fetchSchoolData()
        .then(() => setFetchingSchool(false))
    }, [])

    useEffect(() => {
      const accessToken = localStorage.getItem('accessToken')
      const refreshToken = localStorage.getItem('refreshToken')

      if (accessToken !== null) {
        const split = accessToken.split('.')
        const payload = JSON.parse(atob(split[1]))

        // checks if the access token is expired
        if (isAfter(new Date(), new Date(payload.exp * 1000))) {
          // we use the same callback for both a success or error response
          _refreshAccessToken(refreshToken, endProcessing, endProcessing)
          return () => {}
        }

        _setAccessToken(accessToken, refreshToken)
      } else if (refreshToken !== null) {
        _refreshAccessToken(refreshToken, endProcessing, endProcessing)
        return () => {}
      }

      endProcessing()
      return () => {}
    }, [])

    const handleRolesPermissionsChanged = () => {
      const refreshToken = localStorage.getItem('refreshToken')
      return _refreshAccessToken(refreshToken)
    }

    const ready = !processingToken && !fetchingSchool

    return (
      <>
        {isAuthenticated && (
          <span>
            <Pusher
              channel={`user-${tokenPayload.uid}`}
              event="roles-permissions-updated"
              onUpdate={handleRolesPermissionsChanged}
            />
          </span>
        )}
        {!ready ? <LoadingScreen /> : <WrappedComponent {...props} />}
      </>
    )
  }

  const mapStateToProps = state => ({
    isAuthenticated: state.accessToken.token !== null,
    tokenPayload: state.accessToken.payload,
  })

  const mapDispatchToProps = dispatch => ({
    _refreshAccessToken: (t, s = null, e = null) => dispatch(refreshAccessToken(t, s, e)),
    _setAccessToken: (aT, rT) => dispatch(setAccessToken(aT, rT)),
    _fetchSchoolData: () => dispatch(fetchSchoolData()),
  })

  Component.propTypes = {
    _refreshAccessToken: PropTypes.func.isRequired,
    _setAccessToken: PropTypes.func.isRequired,
    _fetchSchoolData: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    tokenPayload: PropTypes.shape({
      uid: PropTypes.number,
    }),
  }

  Component.defaultProps = {
    tokenPayload: {},
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Component)
}

export default withAppBootstrap
