import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '../../../avatar'
import styles from './CardContent.module.scss'

const CardContent = ({
  profileName, onLogout, profilePicture,
}) => (
  <div className={styles.container}>
    <div className={styles.avatarContainer}>
      <Avatar width={110} height={110} src={profilePicture} />
      <p className={styles.name}>{profileName}</p>
    </div>
    <button type="button" onClick={onLogout} className={styles.logoutBtn}>
      <i className="fas fa-sign-out-alt" />
    </button>
    <small className={styles.logoutLabel}>logout</small>
  </div>
)

CardContent.propTypes = {
  profileName: PropTypes.string.isRequired,
  profilePicture: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
}

export default CardContent
