import React from 'react'
import PropTypes from 'prop-types'

const PageBody = ({ children }) => (<div>{children}</div>)

PageBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
}

export default React.memo(PageBody)
