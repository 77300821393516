import {
  useCallback,
  useEffect, useState,
} from 'react'
import { debounce } from '../helpers/Functions'

const DEBOUNCE = 150

export default () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const updateDimensions = useCallback(() => setWindowDimensions({
    width: window.innerWidth,
    height: window.innerHeight,
  }))

  const handleWindowResize = () => {
    debounce(updateDimensions, DEBOUNCE)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return windowDimensions
}
