import * as RouteNames from '../routes/Routes'
import TeachersMenuItems from './TeachersMenuItems'

export default [
  ...TeachersMenuItems,
  {
    label: 'Dashboard',
    route: RouteNames.DASHBOARD,
    iconClass: 'fas fa-tachometer-alt',
    requiredContext: ['STAFF'],
  },
  {
    label: 'School',
    route: RouteNames.SCHOOL_DETAILS,
    iconClass: 'fas fa-school',
    requiredContext: ['STAFF'],
    requiredPermissions: ['SCHOOL_MANAGEMENT'],
  },
  {
    label: 'Library',
    route: RouteNames.LIBRARY_DASHBOARD,
    iconClass: 'fas fa-swatchbook',
    requiredContext: ['STAFF', 'TEACHER', 'STUDENT'],
    requiredPermissions: ['LIBRARY_MANAGEMENT'],
  },
  {
    label: 'School Years',
    route: RouteNames.SCHOOL_YEARS,
    iconClass: 'fas fa-stopwatch',
    requiredContext: ['STAFF'],
    requiredPermissions: ['SCHOOL_YEARS_MANAGEMENT'],
  },
  {
    label: 'Students',
    route: RouteNames.STUDENTS_LIST,
    iconClass: 'fas fa-user-graduate',
    requiredContext: ['STAFF'],
    requiredPermissions: ['STUDENTS_MANAGEMENT'],
  },
  {
    label: 'Teachers',
    route: RouteNames.TEACHERS_LIST,
    iconClass: 'fas fa-chalkboard-teacher',
    requiredContext: ['STAFF'],
    requiredPermissions: ['TEACHERS_MANAGEMENT'],
  },
  {
    label: 'Disciplines',
    route: RouteNames.DISCIPLINES_LIST,
    iconClass: 'fas fa-book',
    requiredContext: ['STAFF'],
    requiredPermissions: ['DISCIPLINES_MANAGEMENT'],
  },
  {
    label: 'Classes',
    route: RouteNames.CLASSES_LIST,
    iconClass: 'fas fa-users',
    requiredContext: ['STAFF'],
    requiredPermissions: ['CLASSES_MANAGEMENT'],
  },
  {
    label: 'Modules',
    route: RouteNames.MODULES_LIST,
    iconClass: 'fas fa-chalkboard',
    requiredContext: ['STAFF'],
    requiredPermissions: ['MODULES_MANAGEMENT'],
  },
  {
    label: 'Assessments',
    route: RouteNames.ASSESSMENTS_LIST,
    iconClass: 'fas fa-clipboard-list',
    requiredContext: ['STAFF'],
    requiredPermissions: ['ASSESSMENTS_MANAGEMENT'],
  },
  {
    label: 'Staff',
    route: RouteNames.STAFF_LIST,
    iconClass: 'fas fa-address-card',
    requiredContext: ['STAFF'],
    requiredPermissions: ['STAFF_MANAGEMENT'],
  },
  {
    label: 'Parents',
    route: RouteNames.PARENTS_LIST,
    iconClass: 'fas fa-user-tie',
    requiredContext: ['STAFF'],
    requiredPermissions: ['PARENTS_MANAGEMENT'],
  },
  {
    label: 'Unities',
    route: RouteNames.UNITIES_LIST,
    iconClass: 'fas fa-building',
    requiredContext: ['STAFF'],
    requiredPermissions: ['UNITIES_MANAGEMENT'],
  },
  {
    label: 'Calendar',
    route: RouteNames.CALENDAR,
    iconClass: 'fas fa-calendar',
  },
  {
    label: 'Fines',
    route: RouteNames.FINES_LIST,
    iconClass: 'fas fa-money-check-alt',
    requiredContext: ['STAFF'],
    requiredPermissions: ['FINES_MANAGEMENT'],
  },
  {
    label: 'Cloud',
    route: RouteNames.CLOUD,
    iconClass: 'fas fa-cloud',
    requiredContext: ['STAFF'],
    requiredPermissions: ['CLOUD_MANAGEMENT'],
  },
  {
    label: 'Notifications',
    route: RouteNames.NOTIFICATIONS,
    iconClass: 'fas fa-envelope',
    requiredContext: ['STAFF'],
    requiredPermissions: ['NOTIFICATIONS_MANAGEMENT'],
  },
  {
    label: 'Reports',
    route: RouteNames.REPORTS,
    iconClass: 'fas fa-chart-line',
    requiredContext: ['STAFF'],
    requiredPermissions: ['REPORTS_MANAGEMENT'],
  },
  {
    label: 'Roles',
    route: RouteNames.ROLES_LIST,
    iconClass: 'fas fa-id-card',
    requiredContext: ['STAFF'],
    requiredPermissions: ['ROLES_MANAGEMENT'],
  },
  {
    label: 'Tuitions',
    route: RouteNames.TUITIONS_LIST,
    iconClass: 'fas fa-file-invoice-dollar',
    requiredContext: ['STAFF'],
    requiredPermissions: ['TUITIONS_MANAGEMENT'],
  },
]
