export default [
  {
    label: 'Fundamental School 1',
    value: 'FS1',
  },
  {
    label: 'Fundamental School 2',
    value: 'FS2',
  },
  {
    label: 'Medium School',
    value: 'MS',
  },
]
