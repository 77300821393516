import React from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated, config } from 'react-spring'
import styles from './Toast.module.scss'


const Toast = ({ id, message, onDismiss }) => (
  <animated.div
    className={styles.toast}
    style={useSpring({
      top: '35%', from: { top: '-100px' }, config: config.stiff,
    })}
  >
    <div>{message}</div>
    <a href="#" onClick={(e) => { e.preventDefault(); onDismiss(id) }}>
      <i className="fas fa-times" />
    </a>
  </animated.div>
)

Toast.propTypes = {
  id: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
}

export default React.memo(Toast)
