import axios from 'axios'
import Api from '../modules/Api'

export const formatAsTelephone = (string) => {
  if (!string) return string

  if (string.match(/^[0-9]{8}$/)) {
    return `${string.substr(0, 4)}-${string.substr(4, 4)}`
  }

  if (string.match(/^[0-9]{9}$/)) {
    return `${string.substr(0, 5)}-${string.substr(5, 4)}`
  }

  if (string.match(/^[0-9]{10}$/)) {
    return `(${string.substr(0, 2)}) ${string.substr(2, 4)}-${string.substr(6, 4)}`
  }

  if (string.match(/^[0-9]{11}$/)) {
    return `(${string.substr(0, 2)}) ${string.substr(2, 5)}-${string.substr(7, 4)}`
  }

  if (string.match(/^[0-9]{12}$/)) {
    return `(${string.substr(0, 3)}) ${string.substr(3, 5)}-${string.substr(8, 4)}`
  }

  return string
}

export const formatAsCep = string => `${string.substr(0, 5)}-${string.substr(5, 3)}`

export const formatAsCpf = string => `${string.substr(0, 3)}.${string.substr(3, 3)}.${string.substr(6, 3)}-${string.substr(9, 2)}`

const schoolTypes = {
  FS1: 'Fundamental School 1',
  FS2: 'Fundamental School 2',
  MS: 'Medium School',
}
export const schoolTypesLabel = string => schoolTypes[string]

export const round = (number, places) => {
  let multiplier = 1
  for (let i = 0; i < places; i += 1) {
    multiplier *= 10
  }
  return Math.round((number + 0.00001) * multiplier) / multiplier
}

export const capitalizeFirst = string => `${string.charAt(0).toLocaleUpperCase()}${string.slice(1).toLocaleLowerCase()}`

export const shallowCompare = (objA, objB) => {
  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)
  if (keysA.length !== keysB.length) return false
  return keysA.every(keyA => objA[keyA] === objB[keyA])
}

export const debounce = (callback, timeout) => {
  if (typeof callback !== 'function') throw Error('callback must be a function!')
  if (callback.debounceTimeoutId) clearTimeout(callback.debounceTimeoutId)
  // eslint-disable-next-line no-param-reassign
  callback.debounceTimeoutId = setTimeout(callback, timeout)
}

export const getPersonTypeApi = (personType) => {
  switch (personType) {
    case 'TEACHER':
      return Api.teachers
    case 'STUDENT':
      return Api.students
    case 'PARENT':
      return Api.parents
    case 'STAFF':
      return Api.staff
    default:
      throw new Error(`Invalid person type "${personType}"`)
  }
}

export const formatAsCurrency = (decimalString, currencyCode = 'BRL') => parseFloat(decimalString).toLocaleString('pt-BR', {
  style: 'currency',
  currency: currencyCode,
})

export const formatAsZipCode = (zipCodeNumbers) => {
  if (!zipCodeNumbers) return ''
  const match = zipCodeNumbers.match(/^([0-9]{0,5})([0-9]*)/)
  return `${match[1]}-${match[2]}`
}

export const searchZipCode = async (zipCode) => {
  const zipNumbers = zipCode.replace(/\D/g, '')
  if (zipNumbers.length !== 8) throw new Error('Invalid zip code')

  const { data } = await axios.get(`https://viacep.com.br/ws/${zipNumbers}/json/`)
  return {
    streetName: data.logradouro,
    complement: data.complemento,
    neighborhood: data.bairro,
    city: data.localidade,
    state: data.uf,
  }
}

export const minMaxSchoolYear = (schoolType) => {
  switch (schoolType) {
    case 'FS1':
      return [1, 4]
    case 'FS2':
      return [5, 9]
    default:
      return [1, 3]
  }
}
