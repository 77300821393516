import { CLEAR, PUSH, REMOVE } from '../../constants/toast.constants'

export const duration = {
  SHORT: 'SHORT',
  LONG: 'LONG',
}

export const pushMessage = (_duration, message) => (dispatch) => {
  let durationInMs
  switch (_duration) {
    case duration.SHORT:
      durationInMs = 4500
      break
    case duration.LONG:
      durationInMs = 8500
      break
    default:
      throw new Error(`Invalid duration provided ${_duration}`)
  }

  const id = Date.now()

  const timeoutId = setTimeout(() => dispatch({
    type: REMOVE,
    id,
  }), durationInMs)

  dispatch({
    type: PUSH,
    id,
    message,
    timeoutId,
  })
}

export const remove = id => dispatch => dispatch({
  type: REMOVE,
  id,
})

export const clearAll = () => dispatch => dispatch({
  type: CLEAR,
})
