import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { remove } from '../../../redux/actions/toast'
import Toast from './Toast'
import styles from './ToastArea.module.scss'

const ToastArea = ({ toastMessages, clearMessage }) => {
  const handleDismiss = (id) => {
    clearMessage(id)
  }

  return (
    <div className={styles.toastArea}>
      {toastMessages.map(item => (
        <Toast
          key={item.id}
          onDismiss={handleDismiss}
          id={item.id}
          message={item.message}
        />
      ))}
    </div>
  )
}

ToastArea.propTypes = {
  toastMessages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    message: PropTypes.string,
  })).isRequired,
  clearMessage: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  toastMessages: state.toastMessages,
})

const mapDispatchToProps = dispatch => ({
  clearMessage: id => dispatch(remove(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ToastArea)
