import React, { useEffect, useReducer } from 'react'
import axios from 'axios'
import reducer, { actions } from './VersionGuard.reducer'
import styles from './VersionGuard.module.scss'

const CHECK_INTERVAL = 1 // minutes

const VersionGuard = () => {
  const [state, dispatch] = useReducer(reducer, {
    checker: false,
    newVersionAvailable: false,
  })

  useEffect(
    () => {
      let timeoutId = null

      axios.get(`/COMMITHASH?_=${Date.now()}`).then((response) => {
        if (response.data === COMMITHASH) {
          timeoutId = setTimeout(() => {
            dispatch(actions.TOGGLE_CHECKER)
          }, CHECK_INTERVAL * 60000)
        } else {
          dispatch(actions.SET_NEW_VERSION_AVAILABLE)
        }
      })

      return () => clearTimeout(timeoutId)
    },
    [state.checker],
  )

  const handleUpdate = (event) => {
    event.preventDefault()
    window.location.reload(true)
  }

  return (
    state.newVersionAvailable && (
      <div className={styles.versionGuard}>
        <i className="fas fa-bolt mr-2" />
        {' '}
        A new version of this app is available!
        <a href="#" onClick={handleUpdate} className={styles.link}>
          <i className="fas fa-redo-alt mr-2" />
          Reload Page
        </a>
      </div>
    )
  )
}

export default VersionGuard
