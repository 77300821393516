import React from 'react'
import PropTypes from 'prop-types'
import SortLabel from './SortLabel'
import Loading from '../loading'
import './Table.scss'

const Table = (props) => {
  const {
    tableClassName,
    columnsDefinition,
    dataSetKey,
    dataSet,
    currentSortKey,
    currentSortType,
    onSortChange,
    fetchingData,
    noRecordsMessage,
  } = props

  const headerColumns = columnsDefinition.map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <th key={index} className={item.headerClassName} width={item.width}>
      {item.sortable ? (
        <SortLabel
          label={item.label}
          sortKey={item.sortKey}
          currentSortKey={currentSortKey}
          currentSortType={currentSortType}
          onSortChange={onSortChange}
          disabled={fetchingData}
        />
      ) : (
        item.label
      )}
    </th>
  ))

  let bodyRows
  if (fetchingData) {
    bodyRows = (
      <tr>
        <td colSpan={headerColumns.length}>
          <Loading message="Fetching data, please wait..." />
        </td>
      </tr>
    )
  } else {
    bodyRows = dataSet.map((item, rowIndex) => {
      const cells = columnsDefinition.map((columnItem, columnIndex) => {
        const value = columnItem.parse ? columnItem.parse(item, columnIndex, rowIndex) : item[columnItem.dataKey]
        const classNames = typeof columnItem.cellClassName === 'function' ? columnItem.cellClassName(item) : columnItem.cellClassName
        return (
          // eslint-disable-next-line react/no-array-index-key
          <td key={columnIndex} className={classNames}>
            {value}
          </td>
        )
      })

      const key = typeof dataSetKey === 'function' ? dataSetKey(item) : item[dataSetKey]

      return <tr key={key}>{cells}</tr>
    })
    if (bodyRows.length === 0) {
      bodyRows.push(
        <tr key={0}>
          <td className="text-center" colSpan={columnsDefinition.length}>
            {noRecordsMessage}
          </td>
        </tr>,
      )
    }
  }

  return (
    <table className={`table ${tableClassName}`}>
      <thead>
        <tr>{headerColumns}</tr>
      </thead>
      <tbody>{bodyRows}</tbody>
    </table>
  )
}

Table.propTypes = {
  columnsDefinition: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      headerClassName: PropTypes.string,
      cellClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      sortable: PropTypes.bool,
      sortKey: PropTypes.string,
      dataKey: PropTypes.string,
      parse: PropTypes.func,
    }),
  ).isRequired,
  dataSetKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  dataSet: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentSortKey: PropTypes.string,
  currentSortType: PropTypes.oneOf(['asc', 'desc']),
  onSortChange: PropTypes.func,
  tableClassName: PropTypes.string,
  fetchingData: PropTypes.bool,
  noRecordsMessage: PropTypes.string,
}

Table.defaultProps = {
  currentSortKey: '',
  currentSortType: 'asc',
  onSortChange: () => {},
  tableClassName: 'table',
  fetchingData: false,
  noRecordsMessage: 'No records to show',
}

export default Table
