import { STORE_TOKEN, STORE_PAYLOAD, CLEAR_TOKEN } from '../constants/auth.constants'

const defaultToken = {
  token: null,
  payload: null,
}

export const accessToken = (state = defaultToken, action) => {
  switch (action.type) {
    case STORE_TOKEN:
      return { ...state, token: action.token }
    case STORE_PAYLOAD:
      return {
        ...state,
        payload: action.payload,
        permissions: action.payload.permissions,
      }
    case CLEAR_TOKEN:
      return {
        ...state,
        token: null,
        payload: null,
      }
    default:
      return state
  }
}

export default { accessToken }
