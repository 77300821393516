import ApiOperations from './ApiOperations'

export default (instance, baseEndpoint) => ({
  // retrieves a collection of resources
  list: (params = {}) => ApiOperations.get(instance, baseEndpoint)({
    pathParams: {},
    params: {
      ...params,
      page: params.page || 0,
      records: params.records,
      sort: params.sort || null,
      fields: params.fields || null,
      search: params.search || null,
      filter: params.filter || null,
      include: params.include || null,
    },
  }),

  // retrieves a single resource
  retrieve: (id, params = {}) => ApiOperations.get(instance, `${baseEndpoint}/:id`, ['id'])({
    pathParams: { id },
    params,
  }),

  // stores a new resource
  create: (data, include) => ApiOperations.post(instance, baseEndpoint)({
    include,
  }, data),

  // updates a resource
  update: (id, data) => ApiOperations.patch(instance, `${baseEndpoint}/:id`, ['id'])({
    pathParams: { id },
  }, data),

  // deletes a resource
  delete: id => ApiOperations.delete(instance, `${baseEndpoint}/:id`, ['id'])({
    pathParams: { id },
  }),
})
