import CustomDatePicker from 'Components/widgets/date-picker'
import { formatISO, parseISO } from 'date-fns'
import { formatLocalized } from 'Helpers/Dates'
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import FilterType from '../../../types/Filter.type'
import styles from './Filter.module.scss'

const parseFilterString = (s) => {
  const currentFilter = {}
  const providedFilters = s.split('|')
  providedFilters.forEach((f) => {
    const filter = f.split(':')
    if (filter.length < 2) return
    if (!filter[0].length) return
    const key = filter[0]
    const value = filter[1]
    currentFilter[key] = value.split(';')
  })
  return currentFilter
}
const Filter = ({
  filters,
  onApply,
  currentFilterString,
  filterLabel,
}) => {
  const dropdownRef = useRef(null)
  const [selectedFilters, setSelectedFilters] = useState(parseFilterString(currentFilterString))
  const [expanded, setExpanded] = useState(null)

  const handleSelect = (filter, item) => {
    if (!selectedFilters[filter.key]) {
      setSelectedFilters(
        Object.assign({}, selectedFilters, {
          [filter.key]: [item.value],
        }),
      )
      return
    }
    if (selectedFilters[filter.key].find(value => item.value === value)) {
      setSelectedFilters(
        Object.assign({}, selectedFilters, {
          [filter.key]: selectedFilters[filter.key].filter(value => item.value !== value),
        }),
      )
    } else {
      setSelectedFilters(
        Object.assign({}, selectedFilters, {
          [filter.key]: [...selectedFilters[filter.key], item.value],
        }),
      )
    }
  }

  const getFilterSelectionCounter = (filter) => {
    if (!selectedFilters[filter.key]) return 'all'
    if (selectedFilters[filter.key].length === filter.values.length) return 'all'
    return selectedFilters[filter.key].length
  }

  const isFilterSelected = (filter, value) => {
    if (!selectedFilters[filter.key]) return false
    return !!selectedFilters[filter.key].find(_value => _value.toString() === value.toString())
  }

  const isAllSelected = filter => !selectedFilters[filter.key] || selectedFilters[filter.key].length === filter.values.length

  const handleFilterClick = (filter) => {
    if (filter.type === 'multi') {
      if (expanded === filter.key) {
        setExpanded(null)
      } else {
        setExpanded(filter.key)
      }
    } else if (filter.type === 'single') {
      if (!selectedFilters[filter.key]) {
        setSelectedFilters(
          Object.assign({}, selectedFilters, {
            [filter.key]: [filter.value],
          }),
        )
      } else {
        const {
          [filter.key]: a,
          ...res
        } = selectedFilters
        setSelectedFilters(res)
      }
    } else if (filter.type === 'bool' || filter.type === 'boolean') {
      if (!selectedFilters[filter.key]) {
        setSelectedFilters(
          Object.assign({}, selectedFilters, {
            [filter.key]: [0],
          }),
        )
      } else {
        const {
          [filter.key]: a,
          ...res
        } = selectedFilters
        setSelectedFilters(res)
      }
    }
  }

  const handleApply = () => {
    const selection = {}
    filters.forEach((filter) => {
      if (selectedFilters[filter.key]) {
        if (filter.type === 'multi' && selectedFilters[filter.key].length < filter.values.length) {
          selection[filter.key] = selectedFilters[filter.key]
        } else if (filter.type === 'boolean' || filter.type === 'bool') {
          selection[filter.key] = selectedFilters[filter.key]
        } else {
          selection[filter.key] = selectedFilters[filter.key]
        }
      }
    })
    onApply(selection, createFilterString(selection))
  }

  const createFilterString = selection => Object.keys(selection)
    .map(key => `${key}:${selection[key].join(';')}`)
    .join('|')

  const renderFilterElement = (filter, currentFilters) => {
    if (filter.type === 'multi') {
      return (
        <div key={filter.key} className={classnames('btn-group', styles.filterButtonContainer)}>
          <button
            className="btn btn-default dropdown-toggle"
            type="button"
            onClick={() => handleFilterClick(filter)}
            disabled={!filter.enabled}
          >
            {filter.label}
            <span className="ml-2 badge badge-secondary">
              {!filter.enabled ? '-' : getFilterSelectionCounter(filter)}
            </span>
          </button>
          <div
            ref={expanded === filter.key ? dropdownRef : null}
            className={classnames('dropdown-menu', styles.dropdown, {
              show: expanded === filter.key,
            })}
          >
            {filter.values.map(item => (
              <a
                key={item.value}
                className={classnames('dropdown-item d-flex align-items-center', styles.filterItem)}
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  handleSelect(filter, item)
                }}
              >
                <i
                  className={
                    isAllSelected(filter) || isFilterSelected(filter, item.value)
                      ? 'fas fa-check-circle mr-2 text-secondary'
                      : 'far fa-circle mr-2 text-secondary'
                  }
                />
                {item.label}
              </a>
            ))}
          </div>
        </div>
      )
    }
    if (filter.type === 'single') {
      return (
        <div key={filter.key} className="btn-group">
          <button
            className="btn btn-default"
            type="button"
            onClick={() => handleFilterClick(filter)}
            disabled={!filter.enabled}
          >
            <i
              className={
                currentFilters[filter.key] ? 'fas fa-check-circle mr-2 text-secondary' : 'far fa-circle mr-2 text-primary'
              }
            />
            {filter.label}
          </button>
        </div>
      )
    }
    if (filter.type === 'boolean' || filter.type === 'bool') {
      return (
        <div key={filter.key} className="btn-group">
          <button
            className="btn btn-default"
            type="button"
            onClick={() => handleFilterClick(filter)}
            disabled={!filter.enabled}
          >
            <i
              className={
                !currentFilters[filter.key]
                  ? 'fas fa-check-circle mr-2 text-secondary'
                  : 'far fa-circle mr-2 text-secondary'
              }
            />
            {filter.label}
          </button>
        </div>
      )
    }
    if (filter.type === 'date' || filter.type === 'date-month') {
      const format = filter.type === 'date-month' ? 'MMM/yyyy' : 'dd/MM/yyyy'
      return (
        <CustomDatePicker
          showMonthYearPicker={filter.type === 'date-month'}
          isClearable
          key={filter.key}
          selected={currentFilters[filter.key] ? parseISO(currentFilters[filter.key][0]) : null}
          onChange={(d) => {
            setSelectedFilters({
              ...currentFilters,
              [filter.key]: d ? [formatISO(d, { representation: 'date' })] : undefined,
            })
          }}
          placeholder="Reference Date"
          customInput={(
            <button type="button" id={`filter-btn-date-${filter.key}`} className="btn btn-default" style={filter.style}>
              {filter.label}
              <span className="ml-2 badge badge-secondary">
                {currentFilters[filter.key] ? formatLocalized(parseISO(currentFilters[filter.key][0]), format) : '-'}
              </span>
            </button>
          )}
        />
      )
    }
    return ''
  }

  useEffect(
    () => {
      const handler = (e) => {
        if (expanded && !dropdownRef.current.contains(e.target)) {
          setExpanded(false)
        }
      }
      document.addEventListener('mousedown', handler)
      return () => {
        document.removeEventListener('mousedown', handler)
      }
    },
    [expanded],
  )

  return (
    <div className={classnames('d-flex align-items-center mb-3', styles.filterContainer)}>
      <div>
        <i className="fas fa-filter mr-2 text-secondary" />
      </div>
      <div className="btn-group">
        {filters.map(filter => renderFilterElement(filter, selectedFilters))}
        <button type="button" className="btn btn-sm btn-default text-secondary" onClick={handleApply}>
          {filterLabel}
        </button>
      </div>
    </div>
  )
}

Filter.propTypes = {
  filters: PropTypes.arrayOf(FilterType).isRequired,
  onApply: PropTypes.func.isRequired,
  currentFilterString: PropTypes.string,
  filterLabel: PropTypes.string,
}

Filter.defaultProps = {
  currentFilterString: '',
  filterLabel: 'apply',
}

export default Filter
