import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { setAccessToken } from '../../../../redux/actions/auth'
import Api from '../../../../modules/Api'
import * as Routes from '../../../../routes/Routes'
import { PageContainer } from '../../../widgets'
import PageCard from '../../../widgets/card'
import SigninForm from './SigninForm'
import styles from './Signin.module.scss'

const Signin = ({ _setAccessToken, history }) => {
  const school = useSelector(state => state.school)
  const [processing, setProcessing] = useState(false)
  const [fail, setFail] = useState(false)

  const handleSubmit = (formData) => {
    setProcessing(true)
    Api.post('auth', formData)
      .then((response) => {
        if (response.data.success) {
          const { accessToken, refreshToken } = response.data.data
          _setAccessToken(accessToken, refreshToken)
          history.push(Routes.DASHBOARD)
        } else {
          setProcessing(false)
          setFail(true)
        }
      })
      .catch(() => {
        setProcessing(false)
      })
  }

  return (
    <PageContainer title="Authentication Required">
      <div className={styles.schoolName}>{school.name}</div>
      <PageCard
        header={<h5 className="text-center">Authentication Required</h5>}
        footer={(
          <div className="text-center">
            <Link to={Routes.RECOVER_PASSWORD} className="btn btn-default">
              Recover my password
            </Link>
          </div>
)}
        className={styles.mainContainer}
      >
        <SigninForm onSubmit={handleSubmit} processing={processing} fail={fail} />
      </PageCard>
    </PageContainer>
  )
}

const mapDispatchToProps = dispatch => ({
  _setAccessToken: (aT, rT) => dispatch(setAccessToken(aT, rT)),
})

Signin.propTypes = {
  _setAccessToken: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export default connect(
  null,
  mapDispatchToProps,
)(Signin)
