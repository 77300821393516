import {
  format,
  formatDistance,
  formatDistanceStrict,
  formatRelative,
  addYears,
  addMonths,
  addWeeks,
  addDays,
  addBusinessDays,
  addHours,
  addMinutes,
  addSeconds,
  subYears,
  subMonths,
  subWeeks,
  subDays,
  subHours,
  subMinutes,
  subSeconds,
  startOfYear,
  startOfMonth,
  startOfWeek,
  startOfDay,
  startOfHour,
  startOfMinute,
  startOfSecond,
  endOfYear,
  endOfMonth,
  endOfWeek,
  endOfDay,
  endOfHour,
  endOfMinute,
  endOfSecond, parseISO,
} from 'date-fns'
import { ptBR, enUS } from 'date-fns/locale'

const availableLocales = {
  'pt-BR': ptBR,
  'en-US': enUS,
}

let globalLocale = enUS

export const setGlobalLocale = (locale) => {
  globalLocale = availableLocales[locale]
}

// eslint-disable-next-line max-len
export const formatLocalized = (date, formatTokens = 'PPP', options) => {
  let d = date
  if (typeof d === 'string') d = parseISO(d)
  return format(d, formatTokens, { locale: globalLocale, ...options })
}
// eslint-disable-next-line max-len
export const formatDistanceLocalized = (date, baseDate, options) => formatDistance(date, baseDate, { locale: globalLocale, ...options })
// eslint-disable-next-line max-len
export const formatDistanceStrictLocalized = (date, baseDate, options) => formatDistanceStrict(date, baseDate, { locale: globalLocale, ...options })

export const formatRelativeLocalized = (date, baseDate, options) => formatRelative(date, baseDate, { locale: globalLocale, ...options })

export const formatISODate = date => formatISO(date, { representation: 'date' })

export const add = (date, amount, unit) => {
  switch (unit.toLowerCase()) {
    case 'year':
    case 'years':
      return addYears(date, amount)
    case 'month':
    case 'months':
      return addMonths(date, amount)
    case 'week':
    case 'weeks':
      return addWeeks(date, amount)
    case 'day':
    case 'days':
      return addDays(date, amount)
    case 'businessDay':
    case 'businessDays':
      return addBusinessDays(date, amount)
    case 'hour':
    case 'hours':
      return addHours(date, amount)
    case 'minute':
    case 'minutes':
      return addMinutes(date, amount)
    case 'second':
    case 'seconds':
      return addSeconds(date, amount)
    default:
      return new Date(date)
  }
}

export const sub = (date, amount, unit) => {
  switch (unit.toLowerCase()) {
    case 'year':
    case 'years':
      return subYears(date, amount)
    case 'month':
    case 'months':
      return subMonths(date, amount)
    case 'week':
    case 'weeks':
      return subWeeks(date, amount)
    case 'day':
    case 'days':
      return subDays(date, amount)
    case 'hour':
    case 'hours':
      return subHours(date, amount)
    case 'minute':
    case 'minutes':
      return subMinutes(date, amount)
    case 'second':
    case 'seconds':
      return subSeconds(date, amount)
    default:
      return new Date(date)
  }
}

export const startOf = (date, unit) => {
  switch (unit.toLowerCase()) {
    case 'year':
      return startOfYear(date)
    case 'month':
      return startOfMonth(date)
    case 'week':
      return startOfWeek(date)
    case 'day':
      return startOfDay(date)
    case 'hour':
      return startOfHour(date)
    case 'minute':
      return startOfMinute(date)
    case 'second':
      return startOfSecond(date)
    default:
      return new Date(date)
  }
}

export const endOf = (date, unit) => {
  switch (unit.toLowerCase()) {
    case 'year':
      return endOfYear(date)
    case 'month':
      return endOfMonth(date)
    case 'week':
      return endOfWeek(date)
    case 'day':
      return endOfDay(date)
    case 'hour':
      return endOfHour(date)
    case 'minute':
      return endOfMinute(date)
    case 'second':
      return endOfSecond(date)
    default:
      return new Date(date)
  }
}

export const formatISO = (date, options) => formatLocalized(date, "yyyy-MM-dd'T'HH:mm:ssxxx", options)
